import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import SearchCompany from "../../../components/SearchCompany";
import { useGetCompanyAddressListQuery } from "../../../redux/companyApi";
import { initParams } from "../../../utils";

const CompanyAddresses = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState(initParams);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { data, isLoading, refetch } = useGetCompanyAddressListQuery(params, {
    skip: !params.companyId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (selectedCompany) {
      setParams({ ...params, companyId: selectedCompany });
    }
  }, [selectedCompany]);

  const columns = [
    {
      title: t("pages.companyAddress.isDefault"),
      name: "isDefault",
      sortable: true,
      align: "left",
      type: "boolean",
      width: 120,
      renderCell: (rowData) => (rowData?.isDefault ? "E" : "H"),
    },
    {
      title: t("pages.companyAddress.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      width: 100,
    },
    {
      title: t("pages.companyAddress.address1"),
      name: "address1",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.companyAddress.address2"),
      name: "address2",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.companyAddress.phone1"),
      name: "phone1",
      sortable: true,
      align: "left",
      type: "text",
      width: 120,
    },
    {
      title: t("pages.companyAddress.phone2"),
      name: "phone2",
      sortable: true,
      align: "left",
      type: "text",
      width: 120,
    },
    {
      title: t("pages.companyAddress.fax"),
      name: "fax",
      sortable: true,
      align: "left",
      type: "text",
      width: 120,
    },
    {
      title: t("pages.companyAddress.email1"),
      name: "email1",
      sortable: true,
      align: "left",
      type: "text",
      width: 120,
    },
    {
      title: t("pages.companyAddress.email2"),
      name: "email2",
      sortable: true,
      align: "left",
      type: "text",
      width: 120,
    },
    {
      title: t("pages.companyAddress.authorizedPerson"),
      name: "authorizedPerson",
      sortable: true,
      align: "left",
      type: "text",
      width: 120,
    },
  ];
  return (
    <Page title={t("pages.companyAddress.title")}>
      <SearchCompany onChange={(e) => setSelectedCompany(e)} />
      <Divider />
      <DataTable
        create="/company/add-company-address"
        size="compact"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
      />
    </Page>
  );
};

export default CompanyAddresses;
