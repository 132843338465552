import { Edit } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Grid, IconButton, Row } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import TextInput from "../../../components/TextInput";
import { useGetTeachersQuery } from "../../../redux/teacherApi";
import { initParams } from "../../../utils";
import { useNavigate } from "react-router-dom";

const Teachers = () => {
    const { t } = useTranslation();
    const [params, setParams] = useState(initParams);
    const history = useNavigate();
    const formRef = React.useRef();
    const initialValues = {
        name: null,
    };
    const [formValues, setFormValue] = React.useState(initialValues);
    const { data, isLoading, refetch } = useGetTeachersQuery(params, {
        refetchOnMountOrArgChange: true,
    });

    const edit = (data) => { history(`/teacher/${data.id}`); };

    const columns = [
        {
            title: t("pages.teacher.tcKimlikNo"),
            name: "tcKimlikNo",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.teacher.name"),
            name: "name",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.teacher.surname"),
            name: "surname",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.teacher.phone"),
            name: "phone",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.teacher.email"),
            name: "email",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.teacher.teacherTypeId"),
            name: "teacherTypeId",
            sortable: true,
            align: "left",
            type: "enum",
            flexGrow: 1,
            renderCell: (rowData) => <span>{rowData.teacherTypeId === 1 ? t("general.enums.teacherType.advisor") : t("general.enums.teacherType.teacher")}</span>,
        },
        {
            title: t("pages.teacher.countryId"),
            name: "countryId",
            sortable: true,
            align: "left",
            type: "ref",
            flexGrow: 1,
            renderCell: (rowData) => <span>{rowData.teacher_country ? rowData.teacher_country?.name : ""}</span>,
        },
        {
            title: t("general.table.actions"),
            name: "actions",
            width: 100,
            renderCell: (rowData) => (
                <>
                    <IconButton
                        onClick={() => edit(rowData)}
                        size="xs"
                        icon={<Edit color="orange" />}
                    />
                </>
            ),
        },
    ];

    return (
        <Page title={t("pages.teacher.title")}>
            <Form
                fluid
                // model={schema}
                formValue={formValues}
                onChange={(e) => setParams({ ...params, ...e })}
                ref={formRef}
            >
                <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="name"
                                endpoint="teacher/list"
                                label={t("pages.teacher.name")}
                                clearable={false}
                                secondField="surname"
                            />
                        </Col>
                    </Row>
                </Grid>
            </Form>
            <DataTable
                create="/teacher/add"
                size="compact"
                columns={columns}
                rowKey="id"
                loading={isLoading}
                data={data?.data}
                params={params}
                rowCount={data?.total}
                setPagination={setParams}
                refetch={refetch}
            />
        </Page>
    );
};

export default Teachers;
