import React from 'react'
import { useTranslation } from 'react-i18next';
import Page from '../../components/layout/Page'
import Students from '../student/students';

const Home = () => {
    const { t } = useTranslation();

    return (
        <Page title={t("pages.home.title")}>
            <Students />
        </Page>
    )
}

export default Home