import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Panel, Row } from "rsuite";
import StudentEducationComponent from "./StudentEducationComponent";


const StudentEducation = ({ value = [], onChange, fieldError }) => {
    const { t } = useTranslation()
    const errors = fieldError?.studentEducation ? fieldError?.studentEducation?.array : []
    const [studentEducation, setStudentEducation] = React.useState(value)

    const handleStudentEducation = nextStudentEducation => {
        setStudentEducation(nextStudentEducation);
        onChange(nextStudentEducation);
    };

    const handleInputChange = (rowIndex, value) => {
        const nextStudentEducation = [...studentEducation]
        nextStudentEducation[rowIndex] = value
        handleStudentEducation(nextStudentEducation)
    };

    const addNewQuestion = () => {
        const arr = [...studentEducation]
        arr.push({
            educationLevelId: null,
            languageId: null,
            educationId: null,
            advisorId: null,
            price: null,
            paymentTypeId: null,
            advancePayment: null,
            totalInstallmentCount: null,
            dueDate: null,
            startDate: null,
            agencyId: null,
            agencyRate: null,
            discountRate: null,
            discountTypeId: null,
            discountId: null,
        })
        handleStudentEducation(arr)
    }

    const handleMinus = (index) => {
        const arr = [...studentEducation]
        arr.splice(index, 1)
        handleStudentEducation(arr)
    }

    return (
        <Row gutter={1}>
            <ButtonToolbar>
                <Button
                    style={{ marginBottom: 10 }}
                    appearance="primary"
                    onClick={addNewQuestion}
                >
                    {t("pages.teacher.addNewEducation")}
                </Button>
            </ButtonToolbar>
            <Divider />
            {studentEducation.map((rowValue, index) => (
                <>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Panel bordered shaded header={`${t("pages.student.studentEducation.title")} ${index + 1}`} style={{ marginBottom: 8, marginTop: 8 }}>
                            <StudentEducationComponent
                                key={index}
                                rowIndex={index}
                                rowValue={rowValue}
                                rowError={errors[index] ? errors[index].object : null}
                                onChange={handleInputChange}
                                handleMinus={handleMinus}
                            />
                        </Panel>
                    </Col>
                </>
            ))}
        </Row>
    )
}
export default StudentEducation