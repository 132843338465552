import SecurityLayoutRoute from "./SecurityLayout";
import UserLayoutRoute from "./UserLayout";
import Login from "../../pages/user/login";
import NotFound from "./NotFound";
import Home from "../../pages/home";

import AddUser from "../../pages/user/addUser";

import Companies from "../../pages/company/companies";
import AddCompany from "../../pages/company/addCompany";

import AddCompanyAddress from "../../pages/companyAddress/addCompanyAddress";
import CompanyAddresses from "../../pages/companyAddress/companyAddresses";
import Languages from "../../pages/settings/language/languages";
import AddLanguage from "../../pages/settings/language/addLanguage";
import Terms from "../../pages/settings/term/terms";
import AddTerm from "../../pages/settings/term/addTerm";
import EducationLevels from "../../pages/settings/educationLevel/educationLevels";
import AddEducationLevel from "../../pages/settings/educationLevel/addEducationLevel";
import AddEducation from "../../pages/education/addEducation";
import UpdateEducation from "../../pages/education/updateEducation";
import Educations from "../../pages/education/educations";
import AddLesson from "../../pages/lesson/addLesson";
import Lessons from "../../pages/lesson/Lessons";
import UpdateLesson from "../../pages/lesson/updateLesson";
import AddLessonTask from "../../pages/lessonTask/addLessonTask";
import UpdateLessonTask from "../../pages/lessonTask/updateLessonTask";
import LessonTasks from "../../pages/lessonTask/lessonTasks";
import AddLessonVideo from "../../pages/lessonVideo/addLessonVideo";
import LessonVideos from "../../pages/lessonVideo/lessonVideos";
import AddLessonDocument from "../../pages/lessonDocument/addLessonDocument";
import LessonDocuments from "../../pages/lessonDocument/lessonDocuments";
import AddExam from "../../pages/exam/addExam";
import Exams from "../../pages/exam/exams";
import AddTeacher from "../../pages/teacher/addTeacher";
import Teachers from "../../pages/teacher/teachers";
import AddStudent from "../../pages/student/addStudent";
import AddAgency from "../../pages/agency/addAgency";
import Agencies from "../../pages/agency/agencies";
import Students from "../../pages/student/students";
import NextToGraduateStudents from "../../pages/student/nextToGraduateStudents";
import GraduateStudents from "../../pages/student/graduateStudents";
import StudentGrade from "../../pages/student/studentGrade";
import StudentTask from "../../pages/student/studentTask";
import StudentInstallement from "../../pages/student/studentInstallment";
import StudentExpiredPayments from "../../pages/student/studentExpiredPayments";
import UpdateStudent from "../../pages/student/updateStudent";
import DirectGraduate from "../../pages/student/directGraduate";
import SearchCertificate from "../../pages/user/searchCertificate";
import SearchCertificateDaVinci from "../../pages/user/searchCertificatedavinci";
import ChangePassword from "../../pages/user/changePassword";
import AddDiscount from "../../pages/discount/addDiscount";
import Discounts from "../../pages/discount/discounts";
import UpdateTeacher from "../../pages/teacher/updateTeacher";

const routes = [
  {
    path: "/user",
    name: "user",
    component: <UserLayoutRoute />,
    routes: [
      {
        name: "login",
        path: "/user/login",
        component: <Login />,
      },
      {
        name: "searchbcucertificate",
        path: "/user/bcu/certificate",
        component: <SearchCertificate />,
      },
      {
        name: "searchdavincicertificate",
        path: "/user/davinci/certificate",
        component: <SearchCertificateDaVinci />,
      },
      {
        name: "forgotpasword",
        path: "/user/forgot-password",
      },
    ],
  },
  {
    path: "/",
    name: "security",
    component: <SecurityLayoutRoute />,
    routes: [
      {
        name: "dashboard",
        icon: "home",
        path: "/",
        component: <Home />,
      },
      {
        name: "changePassword",
        icon: "home",
        path: "/change-password",
        component: <ChangePassword />,
      },
      {
        name: "user",
        icon: "user",
        path: "/user/add-user",
        component: <AddUser />,
      },
      {
        name: "educations",
        icon: "user",
        routes: [
          {
            name: "education",
            icon: "user",
            path: "/educations/add",
            component: <AddEducation />,
          },
          {
            name: "education",
            icon: "user",
            path: "/educations/list",
            component: <Educations />,
          },
          {
            name: "education",
            icon: "user",
            path: "/educations/:id",
            component: <UpdateEducation />,
          },
        ],
      },
      // {
      //   name: "educationExpertises",
      //   icon: "user",
      //   routes: [
      //     {
      //       name: "addEducationExpertise",
      //       icon: "user",
      //       path: "/education-expertise/add",
      //       component: <AddEducationExpertise />,
      //     },
      //     {
      //       name: "educationExpertises",
      //       icon: "user",
      //       path: "/education-expertise/list",
      //       component: <EducationExpertises />,
      //     },
      //   ],
      // },
      {
        name: "lessons",
        icon: "user",
        routes: [
          {
            name: "lesson",
            icon: "user",
            path: "/lessons/add",
            component: <AddLesson />,
          },
          {
            name: "lesson",
            icon: "user",
            path: "/lessons/list",
            component: <Lessons />,
          },
          {
            name: "lesson",
            icon: "user",
            path: "/lessons/:id",
            component: <UpdateLesson />,
          },
        ],
      },
      {
        name: "lessonTask",
        icon: "user",
        routes: [
          {
            name: "addLessonTask",
            icon: "user",
            path: "/lesson-task/add",
            component: <AddLessonTask />,
          },
          {
            name: "lessonTasks",
            icon: "user",
            path: "/lesson-task/list",
            component: <LessonTasks />,
          },
          {
            name: "lessonTasks",
            icon: "user",
            path: "/lesson-task/:id",
            component: <UpdateLessonTask />,
          },
        ],
      },
      {
        name: "lessonVideo",
        icon: "user",
        routes: [
          {
            name: "addLessonVideo",
            icon: "user",
            path: "/lesson-video/add",
            component: <AddLessonVideo />,
          },
          {
            name: "lessonVideos",
            icon: "user",
            path: "/lesson-video/list",
            component: <LessonVideos />,
          },
        ],
      },
      {
        name: "lessonDocument",
        icon: "user",
        routes: [
          {
            name: "addLessonDocument",
            icon: "user",
            path: "/lesson-document/add",
            component: <AddLessonDocument />,
          },
          {
            name: "lessonDocuments",
            icon: "user",
            path: "/lesson-document/list",
            component: <LessonDocuments />,
          },
        ],
      },
      {
        name: "exam",
        icon: "user",
        routes: [
          {
            name: "addExam",
            icon: "user",
            path: "/exam/add",
            component: <AddExam />,
          },
          {
            name: "exams",
            icon: "user",
            path: "/exam/list",
            component: <Exams />,
          },
        ],
      },
      {
        name: "teacher",
        icon: "user",
        routes: [
          {
            name: "addTeacher",
            icon: "user",
            path: "/teacher/add",
            component: <AddTeacher />,
          },
          {
            name: "teachers",
            icon: "user",
            path: "/teacher/list",
            component: <Teachers />,
          },
          {
            name: "updateteacher",
            icon: "user",
            path: "/teacher/:id",
            component: <UpdateTeacher />,
          },
        ],
      },
      {
        name: "agency",
        icon: "user",
        routes: [
          {
            name: "addAgency",
            icon: "user",
            path: "/agency/add",
            component: <AddAgency />,
          },
          {
            name: "agencies",
            icon: "user",
            path: "/agency/list",
            component: <Agencies />,
          },
        ],
      },
      {
        name: "student",
        icon: "user",
        routes: [
          {
            name: "addStudent",
            icon: "user",
            path: "/student/add",
            component: <AddStudent />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/list",
            component: <Students />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/:id",
            component: <UpdateStudent />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/next-to-graduate-student-list",
            component: <NextToGraduateStudents />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/graduated-student-list",
            component: <GraduateStudents />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/grade",
            component: <StudentGrade />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/task",
            component: <StudentTask />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/installment",
            component: <StudentInstallement />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/direct-graduate",
            component: <DirectGraduate />,
          },
          {
            name: "students",
            icon: "user",
            path: "/student/expired-payments",
            component: <StudentExpiredPayments />,
          },
        ],
      },
      {
        name: "settings",
        icon: "user",
        routes: [
          {
            name: "language",
            icon: "user",
            path: "/settings/language/add",
            component: <AddLanguage />,
          },
          {
            name: "language",
            icon: "user",
            path: "/settings/language/list",
            component: <Languages />,
          },
        ],
      },
      {
        name: "settings",
        icon: "user",
        routes: [
          {
            name: "term",
            icon: "user",
            path: "/settings/term/add",
            component: <AddTerm />,
          },
          {
            name: "term",
            icon: "user",
            path: "/settings/term/list",
            component: <Terms />,
          },
        ],
      },
      {
        name: "settings",
        icon: "user",
        routes: [
          {
            name: "educationLevel",
            icon: "user",
            path: "/settings/education-level/add",
            component: <AddEducationLevel />,
          },
          {
            name: "term",
            icon: "user",
            path: "/settings/education-level/list",
            component: <EducationLevels />,
          },
          {
            name: "discount",
            icon: "user",
            path: "/settings/discount/add",
            component: <AddDiscount />,
          },
          {
            name: "discount",
            icon: "user",
            path: "/settings/discount/list",
            component: <Discounts />,
          },
        ],
      },
      {
        name: "company",
        icon: "user",
        path: "/company",
        routes: [
          {
            name: "companies",
            icon: "user",
            path: "/company/companies",
            component: <Companies />,
          },
          {
            name: "companies",
            icon: "user",
            path: "/company/add",
            component: <AddCompany />,
          },
        ],
      },
      {
        name: "companyAddress",
        icon: "user",
        routes: [
          {
            name: "addcompanies",
            icon: "user",
            path: "/company/add-company-address",
            component: <AddCompanyAddress />,
          },
          {
            name: "companyAdresses",
            icon: "user",
            path: "/company/company-addresses",
            component: <CompanyAddresses />,
          },
        ],
      },
      {
        name: "order",
        icon: "user",
        routes: [
          {
            name: "addOrder",
            icon: "user",
            path: "/order/add",
            // component: <AddOrder />,
          },
          {
            name: "addOrder",
            icon: "user",
            path: "/order/list",
            // component: <Orders />,
          },
          {
            name: "orderDefinitions",
            icon: "user",
            routes: [],
          },
        ],
      },
    ],
  },

  {
    path: "*",
    name: "notfound",
    component: <NotFound />,
  },
];

export default routes;
