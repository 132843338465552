import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { InputPicker, Loader } from 'rsuite';
import { useAppSelector } from "../../hooks";
import { useRefQueryQuery } from "../../redux/api";
import { getCurrentUser } from "../../redux/authSlice";
import { initParams } from "../../utils";

const RefDropdown = ({
    onChange,
    value,
    labelKey = "name",
    renderMenuColumns = null,
    disabled = false,
    endpoint = null,
    setDefaultValue = false,
    setUserDefaultValue = false,
    placeholder = null,
    query = {},
    skip = null,
    cleanable = true,
    creatable = false,
    onCreate,
    searchKey = "name",
    ...rest }) => {
    const { t } = useTranslation();
    const [key, setKey] = useState(null)
    const { data, isLoading } = useRefQueryQuery({ ...initParams, [searchKey]: key, endpoint, ...query }, { refetchOnMountOrArgChange: true, skip: skip })
    const currentUser = useAppSelector(getCurrentUser)

    const onSearch = (key) => {
        if (key.length > 1)
            setKey(key)
    }
    let defaultValue = null
    if (!value) {
        if (setDefaultValue) {
            defaultValue = data?.data.find(f => f.isDefault)?.id
        }
        else if (setUserDefaultValue) {
            defaultValue = currentUser?.user_userDefaults[rest.name]
        }
        else defaultValue = null
    }
    else defaultValue = value
    // let defaultaValue = typeof value === "undefined" ? setDefaultValue ? data?.data.find(f => f.isDefault)?.id : null : value

    return (
        <InputPicker
            creatable={creatable}
            onCreate={onCreate}
            disabled={disabled}
            block
            data={data?.data}
            labelKey="name"
            valueKey="id"
            onSearch={onSearch}
            onChange={onChange}
            cleanable={cleanable}
            value={defaultValue}
            placeholder={placeholder || t("general.search")}
            renderMenu={menu => {
                if (isLoading) {
                    return (
                        <div style={{ padding: 10, color: '#999', textAlign: 'center' }}>
                            <Loader /> {t("general.loading")}
                        </div>
                    );
                }
                if (renderMenuColumns) {
                    return //TODO
                }
                return menu;
            }}
        />
    );
}

export default RefDropdown