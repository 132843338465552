import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Form, Grid, Notification, Row, Schema, useToaster } from "rsuite";
import Page from "../../../components/layout/Page";
import ResetButton from "../../../components/ResetButton";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useAddAgencyMutation } from "../../../redux/agencyApi";
import EnumDropdown from "../../../components/EnumDropdown";
import { AgencyTypeOptions } from "../../../utils/options";

const { StringType, NumberType } = Schema.Types

const AddAgency = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const toaster = useToaster();
    const [createAgency, { isLoading }] = useAddAgencyMutation()

    const schema = Schema.Model({
        name: StringType().isRequired(t('pages.agency.valid.name_required')).minLength(1, t("pages.agency.valid.name_minLength")).maxLength(32, t("pages.agency.valid.name_maxLength")),
        agencyTypeId: NumberType().isRequired(t('pages.agency.valid.agencyTypeId_required')),
        phone1: StringType().isRequired(t('pages.agency.valid.phone1_required')),
        email1: StringType().isRequired(t('pages.agency.valid.email1_required')),
        personName: StringType().isRequired(t('pages.agency.valid.personName_required')),
        personSurname: StringType().isRequired(t('pages.agency.valid.personSurname_required')),
        personPhone: StringType().isRequired(t('pages.agency.valid.personPhone_required')),
        personEmail: StringType().isRequired(t('pages.agency.valid.personEmail_required')),
        address: StringType().isRequired(t('pages.agency.valid.address_required')),
    })

    const initialValues = {
        name: "",
        agencyTypeId: null,
        taxNo: null,
        taxOffice: null,
        phone1: null,
        phone2: null,
        email1: null,
        email2: null,
        personName: null,
        personSurname: null,
        personPhone: null,
        personEmail: null,
        address: null,
    }

    const [formValues, setFormValue] = React.useState(initialValues)

    const onSubmit = async () => {
        if (!formRef.current.check()) {
            toaster.push(<Notification closable type="error" header={t('general.error.validation_error')} duration={2000}><p>{t('general.error.validation_error_description')}</p></Notification>, { placement: "topEnd" })
            return;
        }
        await createAgency(formValues).unwrap().then((response) => {
            toaster.push(<Notification closable type="success" header={t('general.error.success')} duration={2000}><p>{t('general.error.success_add')}</p></Notification>, { placement: "topEnd" })
        }
        ).catch((error) => {
            toaster.push(<Notification closable type="error" header={t('general.error.error')} duration={2000}><p>{error?.data?.description}</p></Notification>, { placement: "topEnd" })
        });
    }


    return (
        <Page title={t('pages.agency.add_title')}>
            <Form fluid
                model={schema}
                formValue={formValues}
                onChange={setFormValue}
                ref={formRef}>
                <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="name"
                                label={t('pages.agency.name')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="agencyTypeId"
                                label={t("pages.agency.agencyTypeId")}
                                accepter={EnumDropdown}
                                data={AgencyTypeOptions}
                                prefix="general.enums.agencyType"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="taxOffice"
                                label={t('pages.agency.taxOffice')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="taxNo"
                                label={t('pages.agency.taxNo')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="phone1"
                                label={t('pages.agency.phone1')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="phone2"
                                label={t('pages.agency.phone2')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="email1"
                                label={t('pages.agency.email1')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="email2"
                                label={t('pages.agency.email2')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="personName"
                                label={t('pages.agency.personName')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="personSurname"
                                label={t('pages.agency.personSurname')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="personPhone"
                                label={t('pages.agency.personPhone')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="personEmail"
                                label={t('pages.agency.personEmail')}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextInput
                                name="address"
                                label={t('pages.agency.address')}
                                rows={3}
                                accepter={TextArea}
                            />
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <Divider />
                    <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button color="green" appearance="primary" onClick={onSubmit} loading={isLoading}>
                            {t("general.save")}
                        </Button>
                        <ResetButton onOk={() => setFormValue(initialValues)} disabled={isLoading} />
                    </ButtonToolbar>
                </Form.Group>
            </Form>

        </Page>
    )
}

export default AddAgency