import { Edit, Trash } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Grid,
  IconButton,
  Modal,
  Row,
  useToaster,
} from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import TextInput from "../../../components/TextInput";
import { useAppDispatch } from "../../../hooks";
import useUser from "../../../hooks/useUser";
import { api } from "../../../redux/api";
import {
  useGetStudentElasticsQuery,
  useGetStudentsQuery,
} from "../../../redux/studentApi";
import { initParams } from "../../../utils";

const Students = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedId, setId] = React.useState(false);
  const [params, setParams] = useState({ ...initParams, paging: true });
  const formRef = React.useRef();
  const toaster = useToaster();
  const initialValues = {
    name: null,
  };
  const [formValues, setFormValue] = React.useState(initialValues);
  const [currentUser] = useUser();
  const { data, isLoading, refetch } = useGetStudentElasticsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const edit = (data) => {
    history(`/student/${data.id}`);
  };

  const remove = () => {
    // setLoading(true)
    dispatch(
      api.endpoints.deleteStudent.initiate(
        { id: selectedId },
        { forceRefetch: true }
      )
    )
      .unwrap()
      .then((res) => {
        // setLoading(false);
        if (res.status === "success") {
          setId(null);
          handleModalClose();
        }
      })
      .catch((err) => {
        // setLoading(false)
      });
  };

  const handleModalOpen = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
    setId(null);
    refetch();
  };

  const columns = [
    // {
    //   title: t("pages.student.countryId"),
    //   name: "countryId",
    //   sortable: true,
    //   align: "left",
    //   type: "ref",
    //   flexGrow: 1,
    //   renderCell: (rowData) => (
    //     <span>
    //       {rowData.student_country ? rowData.student_country.name : ""}
    //     </span>
    //   ),
    // },
    {
      title: t("pages.student.tcKimlikNo"),
      name: "tcKimlikNo",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.surname"),
      name: "surname",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.email"),
      name: "email",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.phone"),
      name: "phone",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("general.table.actions"),
      name: "actions",
      width: 100,
      renderCell: (rowData) => (
        <>
          <IconButton
            onClick={() => edit(rowData)}
            size="xs"
            icon={<Edit color="orange" />}
          />
          <IconButton
            onClick={() => handleModalOpen(rowData.id)}
            size="xs"
            icon={<Trash color="red" />}
          />
        </>
      ),
    },
  ];

  return (
    <Page title={t("pages.student.title")}>
      <Form
        fluid
        // model={schema}
        formValue={formValues}
        onChange={(e) => setParams({ ...params, ...e })}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="name"
                endpoint="student/search"
                label={t("pages.student.studentGrade.studentId")}
                clearable={false}
                secondField="surname"
              />
            </Col>
          </Row>
        </Grid>
      </Form>
      <DataTable
        create={currentUser && currentUser.roleId === 1 && "/student/add"}
        size="compact"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
      />
      <Modal
        size="xs"
        role="alertdialog"
        backdrop="static"
        keyboard={false}
        open={open}
        onClose={handleModalClose}
      >
        <Modal.Header>
          <Modal.Body>{t("Öğrenciyi silmek istiyor musunuz?")}</Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={remove} appearance="primary">
            {t("general.yes")}
          </Button>
          <Button onClick={handleModalClose} appearance="subtle">
            {t("general.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Page>
  );
};

export default Students;
