import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Form, Grid, InputNumber, Notification, Panel, Row, Schema, useToaster } from "rsuite";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import ResetButton from "../../../components/ResetButton";
import TextInput from "../../../components/TextInput";
import { useAddExamMutation } from "../../../redux/examApi";
import Questions from "../components/Questions";

const { StringType, NumberType, ArrayType, ObjectType, BooleanType } = Schema.Types

const AddExam = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const toaster = useToaster();
    const [createExam, { isLoading }] = useAddExamMutation()

    const schema = Schema.Model({
        educationId: NumberType()
            .isRequired(t("pages.exam.valid.educationId_required")),
        classicQuestionCount: NumberType()
            .isRequired(t('pages.exam.valid.classicQuestionCount_required'))
            .min(0, t("pages.exam.valid.classicQuestionCount_min")),
        testQuestionCount: NumberType()
            .isRequired(t('pages.exam.valid.testQuestionCount_required'))
            .min(0, t("pages.exam.valid.testQuestionCount_min")),
        examQuestions: ArrayType().of(
            ObjectType().shape({
                questionTypeId: NumberType()
                    .isRequired(t('pages.exam.examQuestions.valid.questionTypeId_required')),
                question: StringType()
                    .isRequired(t('pages.exam.examQuestions.valid.question_required'))
                    .minLength(1, t('pages.exam.examQuestions.valid.question_minLength'))
                    .maxLength(1024, t('pages.exam.examQuestions.valid.question_maxLength')),
                examQuestionOptions: ArrayType().of(
                    ObjectType().shape({
                        optionDescription: StringType()
                            .isRequired(t('pages.exam.examQuestions.examQuestionOptions.valid.optionDescription_required'))
                            .minLength(1, t('pages.exam.examQuestions.examQuestionOptions.valid.optionDescription_minLength'))
                            .maxLength(1024, t('pages.exam.examQuestions.examQuestionOptions.valid.optionDescription_maxLength')),
                        isCorrect: BooleanType()
                    })
                ).minLength(2, t("pages.exam.examQuestions.examQuestionOptions.valid.array_minLength"))
            })
        )
    })

    const initialValues = {
        educationId: null,
        lessonId: null,
        testQuestionCount: 1,
        classicQuestionCount: 0,
        examQuestions: [
            {
                questionTypeId: null,
                question: "",
                photo: "",
                examQuestionOptions: [
                    {
                        optionDescription: "",
                        isCorrect: false
                    }
                ]
            }
        ]

    }

    const [formValues, setFormValue] = React.useState(initialValues)
    const [formError, setFormError] = React.useState({});

    const onSubmit = async () => {
        if (!formRef.current.check()) {
            toaster.push(<Notification closable type="error" header={t('general.error.validation_error')} duration={2000}><p>{t('general.error.validation_error_description')}</p></Notification>, { placement: "topEnd" })
            return;
        }
        await createExam(formValues).unwrap().then((response) => {
            toaster.push(<Notification closable type="success" header={t('general.error.success')} duration={2000}><p>{t('general.error.success_add')}</p></Notification>, { placement: "topEnd" })
        }
        ).catch((error) => {
            toaster.push(<Notification closable type="error" header={t('general.error.error')} duration={2000}><p>{error?.data?.description}</p></Notification>, { placement: "topEnd" })
        });
    }
    console.log(formValues)
    return (
        <Page title={t('pages.exam.add_title')}>
            <Form fluid
                model={schema}
                formValue={formValues}
                onChange={setFormValue}
                onCheck={setFormError}
                ref={formRef}>
                <Grid fluid>
                    <Row gutter={16}>
                        <Panel bordered header="Sınav Genel Bilgileri" shaded>
                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <TextInput
                                    name="educationId"
                                    endpoint="education/list"
                                    label={t("pages.exam.educationId")}
                                    cleanable={false}
                                    accepter={RefDropdown}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <TextInput
                                    name="lessonId"
                                    endpoint="lesson/list"
                                    label={t("pages.exam.lessonId")}
                                    accepter={RefDropdown}
                                    query={{ educationId: formValues.educationId }}
                                    skip={!formValues.educationId}
                                    disabled={!formValues.educationId}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <TextInput
                                    name="testQuestionCount"
                                    label={t("pages.exam.testQuestionCount")}
                                    accepter={InputNumber}
                                    min={1}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <TextInput
                                    name="classicQuestionCount"
                                    label={t("pages.exam.classicQuestionCount")}
                                    accepter={InputNumber}
                                    min={0}
                                />
                            </Col>
                        </Panel>
                    </Row>
                    <Row gutter={16}>
                        <Divider>{t("pages.exam.questions")}</Divider>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Control
                                name="examQuestions"
                                accepter={Questions}
                                fieldError={formError}
                            />
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <Divider />
                    <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button color="green" appearance="primary" onClick={onSubmit} loading={isLoading}>
                            {t("general.save")}
                        </Button>
                        <ResetButton onOk={() => setFormValue(initialValues)} disabled={isLoading} />
                    </ButtonToolbar>
                </Form.Group>
            </Form>

        </Page>
    )
}

export default AddExam