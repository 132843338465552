import { Check, FileDownload } from "@rsuite/icons";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Form,
  Grid,
  IconButton,
  Notification,
  Row,
  Schema,
  useToaster,
} from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import SearchStudent from "../../../components/SearchStudent";
import TextInput from "../../../components/TextInput";
import {
  useGetTaskListByStudentEducationIdQuery,
  useApproveStudentTaskMutation,
} from "../../../redux/studentApi";
import { defaultToolbar, getEnumName, initParams } from "../../../utils";
import { StudentTaskStatusEnum } from "../../../utils/enums";
const { NumberType } = Schema.Types;

const StudentTask = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const [params, setParams] = useState(initParams);
  const initialValues = {
    studentId: null,
    studentEducationId: null,
  };
  const [formValues, setFormValue] = React.useState(initialValues);

  const { data, isLoading, refetch } = useGetTaskListByStudentEducationIdQuery(
    { ...params, studentEducationId: formValues.studentEducationId },
    {
      refetchOnMountOrArgChange: true,
      skip: !formValues.studentEducationId,
    }
  );
  const [approveStudentTask, { isLoading: isApproving }] =
    useApproveStudentTaskMutation();

  const schema = Schema.Model({
    studentId: NumberType().isRequired(
      t("pages.student.studentGrade.valid.studentId_required")
    ),
    studentEducationId: NumberType().isRequired(
      t("pages.student.studentGrade.valid.studentEducationId_required")
    ),
  });

  const columns = [
    {
      title: t("general.table.approve"),
      name: "actions",
      width: 75,
      renderCell: (rowData) => (
        <>
          {(rowData.statusId === StudentTaskStatusEnum.updated ||
            rowData.statusId === StudentTaskStatusEnum.submittedForGrading) && (
            <IconButton
              onClick={() => approveTask(rowData)}
              size="xs"
              icon={<Check color="green" />}
            />
          )}
        </>
      ),
    },
    {
      title: t("general.table.download"),
      name: "actions",
      width: 75,
      renderCell: (rowData) => (
        <>
          {rowData?.statusId === StudentTaskStatusEnum.updated ||
            (rowData?.statusId ===
              StudentTaskStatusEnum.submittedForGrading && (
              <a
                // download={rowData?.path?.substring(
                //   rowData?.path.lastIndexOf("/") + 1
                // )}
                download={`https://admin.bcuni.eu/api/public/documents/${rowData?.path}`}
                target="_blank"
                href={`https://admin.bcuni.eu/api/public/documents/${rowData?.path}`}
              >
                İndir
              </a>
            ))}
          {/* {rowData.statusId !== StudentTaskStatusEnum.waitingForUpload && (
            <IconButton
              onClick={() => download(rowData)}
              size="xs"
              icon={<FileDownload color="blue" />}
            />
          )} */}
        </>
      ),
    },
    {
      title: t("pages.student.studentTask.statusId"),
      name: "statusId",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
      renderCell: (rowData) => (
        <>
          {t(
            `general.enums.studentTask.${getEnumName(
              StudentTaskStatusEnum,
              rowData.statusId
            )}`
          )}
        </>
      ),
    },
    {
      title: t("pages.student.studentGrade.lessonCode"),
      name: "studentTask_lessonTask.lessonTask_lesson.code",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
      renderCell: (rowData) => (
        <>
          {rowData.studentTask_lessonTask.lessonTask_lesson
            ? rowData.studentTask_lessonTask.lessonTask_lesson.code +
              " - " +
              rowData.studentTask_lessonTask.lessonTask_lesson.name
            : ""}
        </>
      ),
    },
    {
      title: t("pages.student.studentTask.name"),
      name: "studentTask_lessonTask.name",
      sortable: true,
      align: "left",
      type: "number",
      // fullText: true,
      // fixed: true,
      flexGrow: 1,
    },
    {
      title: t("pages.student.studentTask.description"),
      name: "studentTask_lessonTask.description",
      sortable: true,
      // fixed: true,
      // fullText: true,
      align: "left",
      type: "number",
      flexGrow: 1,
    },
    {
      title: t("pages.student.studentTask.startDate"),
      name: "startDate",
      sortable: true,
      align: "left",
      type: "number",
      flexGrow: 1,
      renderCell: (rowData) => (
        <>
          {rowData.startDate
            ? moment(rowData.startDate).format("DD/MM/YYYY")
            : "-"}
        </>
      ),
    },
    {
      title: t("pages.student.studentTask.endDate"),
      name: "endDate",
      sortable: true,
      align: "left",
      type: "number",
      flexGrow: 1,
      renderCell: (rowData) => (
        <>
          {rowData.endDate ? moment(rowData.endDate).format("DD/MM/YYYY") : "-"}
        </>
      ),
    },
    {
      title: t("pages.student.studentTask.studentDescription"),
      name: "description",
      sortable: true,
      align: "left",
      // fixed: true,
      // fullText: true,
      type: "number",
      flexGrow: 1,
    },
  ];

  const download = (data) => {
    let a = document.createElement("a");
    a.href = `https://admin.bcuni.eu/static${data.path}`;
    a.download = data.path.substring(data.path.lastIndexOf("/") + 1);
    a.click();
    // setFormValue({ ...formValues, lessonId: data.lessonId, id: data.id, grade: data.grade })
    // setSelectedRow(data)
    // setShowModal(true)
  };

  const approveTask = async (data) => {
    await approveStudentTask({ id: data.id })
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        refetch();
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    // await updateStudentGrade(formValues).unwrap().then((response) => {
    //     toaster.push(<Notification closable type="success" header={t('general.error.success')} duration={2000}><p>{t('general.error.success_add')}</p></Notification>, { placement: "topEnd" })
    // }
    // ).catch((error) => {
    //     toaster.push(<Notification closable type="error" header={t('general.error.error')} duration={2000}><p>{error?.data?.description}</p></Notification>, { placement: "topEnd" })
    // });
  };

  return (
    <Page title={t("pages.student.studentTask.title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              {/* <TextInput
                                name="studentId"
                                endpoint="student/list"
                                label={t("pages.student.studentGrade.studentId")}
                                accepter={RefDropdown}
                                clearable={false}
                                secondField="surname"
                            /> */}
              <TextInput
                name="studentId"
                label={t("pages.student.studentGrade.studentId")}
                accepter={SearchStudent}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="studentEducationId"
                endpoint="student/education"
                label={t("pages.student.studentGrade.studentEducationId")}
                accepter={RefDropdown}
                skip={!formValues.studentId}
                query={{ studentId: formValues.studentId }}
              />
            </Col>
          </Row>
        </Grid>
        <DataTable
          size="compact"
          columns={columns}
          rowKey="id"
          loading={isLoading || isApproving}
          data={data?.data}
          params={params}
          rowCount={data?.total}
          setPagination={setParams}
          toolbar={{ ...defaultToolbar, filter: false }}
        />
      </Form>
    </Page>
  );
};

export default StudentTask;
