import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { InputPicker, Loader } from 'rsuite';
import { useGetCityListQuery } from "../../redux/api";
import { initParams } from "../../utils";

const SearchCity = ({ onChange, value, disabled = false, countryId }) => {
    const { t } = useTranslation();
    const [key, setKey] = useState(null)
    const { data, isLoading } = useGetCityListQuery({ ...initParams, name: key, countryId }, { refetchOnMountOrArgChange: true })

    const onSearch = (key) => {
        if (key.length > 1)
            setKey(key)
    }

    return (
        <InputPicker
            disabled={disabled}
            block
            data={data?.data}
            labelKey="name"
            valueKey="id"
            onSearch={onSearch}
            onChange={onChange}
            value={value}
            placeholder={t("pages.utils.searchCity")}
            renderMenu={menu => {
                if (isLoading) {
                    return (
                        <div style={{ padding: 10, color: '#999', textAlign: 'center' }}>
                            <Loader /> {t("general.loading")}
                        </div>
                    );
                }
                return menu;
            }}
        />
    );
}

export default SearchCity