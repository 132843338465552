import { Nav } from "rsuite";

const StudentNavbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ marginBottom: 50 }}
    >
      <Nav.Item eventKey="educations">Eğitimler</Nav.Item>
      {/* <Nav.Item eventKey="tasks">Ödevler</Nav.Item>
            <Nav.Item eventKey="grades">Notlar</Nav.Item>
            <Nav.Item eventKey="videos">Videolar</Nav.Item> */}
    </Nav>
  );
};

export default StudentNavbar;
