import { Edit } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import { useGetExamsQuery } from "../../../redux/examApi";
import { defaultToolbar, initParams } from "../../../utils";

const Exams = () => {
    const { t } = useTranslation();
    const [params, setParams] = useState(initParams);
    const { data, isLoading, refetch } = useGetExamsQuery(params, {
        refetchOnMountOrArgChange: true,
    });

    const edit = (data) => { };

    const columns = [
        {
            title: t("pages.exam.educationId"),
            name: "educationId",
            sortable: true,
            align: "left",
            type: "ref",
            flexGrow: 1,
            renderCell: (rowData) => <span>{rowData.exam_education ? rowData.exam_education?.code + " - " + rowData.exam_education?.name : ""}</span>,
        },
        {
            title: t("pages.exam.lessonId"),
            name: "lessonId",
            sortable: true,
            align: "left",
            type: "ref",
            flexGrow: 1,
            renderCell: (rowData) => <span>{rowData.exam_lesson ? rowData.exam_lesson?.code + " - " + rowData.exam_lesson?.name : ""}</span>,
        },
        {
            title: t("pages.exam.testQuestionCount"),
            name: "testQuestionCount",
            sortable: true,
            align: "left",
            type: "number",
            flexGrow: 1,
        },
        {
            title: t("pages.exam.classicQuestionCount"),
            name: "classicQuestionCount",
            sortable: true,
            align: "left",
            type: "number",
            flexGrow: 1,
        },
        {
            title: t("general.table.actions"),
            name: "actions",
            width: 100,
            renderCell: (rowData) => (
                <>
                    <IconButton
                        onClick={() => edit(rowData)}
                        size="xs"
                        icon={<Edit color="blue" />}
                    />
                    <IconButton
                        onClick={() => edit(rowData)}
                        size="xs"
                        icon={<Edit color="green" />}
                    />
                </>
            ),
        },
    ];

    return (
        <Page title={t("pages.exam.title")}>
            <DataTable
                create="/exam/add"
                size="compact"
                columns={columns}
                rowKey="id"
                loading={isLoading}
                data={data?.data}
                params={params}
                rowCount={data?.total}
                setPagination={setParams}
                refetch={refetch}
                toolbar={{ ...defaultToolbar, filter: false }}
            />
        </Page>
    );
};

export default Exams;
