import { Paragraph } from "@rsuite/icons";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Col, FlexboxGrid, Form, Grid, Notification, Panel, Row, Schema, Steps, useToaster } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import StepForm from "../../../components/stepForm";
const { StringType } = Schema.Types

const AddUser = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const toaster = useToaster()

    const model = Schema.Model({
        userName: StringType().isRequired(t('pages.user.login.valid.usernameRequired')),
    })
    const [formValues, setFormValue] = React.useState({
        userName: '',
    })

    const onSubmit = () => {
        if (!formRef.current.check()) {
            toaster.push(<Notification closable type="error" header="Validation Error" duration={2000}><p>Validation Error</p></Notification>, { placement: "topEnd" })
            return;
        }
        toaster.push(<Notification closable type="success" header="Başarılı" duration={2000}><p>Ekleme işlemi başarılı</p></Notification>, { placement: "topEnd" })
    }
    
    return (
        <Page title={t("pages.user.addUser.title")}>
            <StepForm
                setFormValue={setFormValue}
                initalValues={formValues}
                schema={model}
                formRef={formRef}
                steps={[
                    { description: "General Information" },
                    { description: "Other Information" },
                    { description: "Review" }]
                }
                onSubmit={onSubmit}>
                <Panel key={0} header={`Step: 1`}>
                    <Grid fluid>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Group>
                                    <Form.ControlLabel>{t('pages.user.login.username')}</Form.ControlLabel>
                                    <Form.Control name="userName" />
                                </Form.Group>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={4}>
                                <Form.Group>
                                    <Form.ControlLabel>{t('pages.user.login.username')}</Form.ControlLabel>
                                    <Form.Control name="pass" />
                                </Form.Group>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={4}>
                                <Form.Group>
                                    <Form.ControlLabel>{t('pages.user.login.username')}</Form.ControlLabel>
                                    <Form.Control name="asdsd" />
                                </Form.Group>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={4}>
                                <Form.Group>
                                    <Form.ControlLabel>{t('pages.user.login.username')}</Form.ControlLabel>
                                    <Form.Control name="dsas" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Grid>

                </Panel>
                <Panel key={1} header={`Step: 2`}>
                    <Paragraph />
                </Panel>
                <Panel key={2} header={`Step: 3`}>
                    <Paragraph />
                </Panel>
            </StepForm>
            <DataTable size="compact" columns={[
                {
                    title: "Id",
                    name: "id",
                    width: 100,
                    sortable: true,
                    align: "left",
                    type: "number",
                },
                {
                    title: "Name",
                    name: "name",
                    // width: 50,
                    sortable: false,
                    align: "center",
                    type: "text"
                },
            ]} />
            {/* <FlexboxGrid justify="center">
                <FlexboxGrid.Item as={Col} md={12} xs={24}>
                    <Steps current={step}>
                        <Steps.Item title={t("general.finished")} description="General Information" />
                        <Steps.Item title={t("general.inProgress")} description="Other Information  " />
                    </Steps>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <Panel header={`Step: ${step + 1}`}>
                <Paragraph />
            </Panel>
            <hr />
            <ButtonGroup style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={onPrevious} disabled={step === 0}>
                    {t("general.previous")}
                </Button>
                <Button onClick={onNext} disabled={step === 1}>
                    {t("general.next")}
                </Button>
            </ButtonGroup> */}
        </Page>
    )
}
export default AddUser