import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import { useGetDiscountListQuery } from "../../../redux/settingApi";
import { initParams } from "../../../utils";

const Discounts = () => {
    const { t } = useTranslation();

    const [params, setParams] = useState(initParams)
    const { data, isLoading, refetch } = useGetDiscountListQuery(params, { refetchOnMountOrArgChange: true })

    const columns = [
        {
            title: t("pages.discount.name"),
            name: "name",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 2
        },
        {
            title: t("pages.discount.description"),
            name: "description",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 2
        },
    ]

    return (
        <Page title={t("pages.discount.title")}>
            <DataTable
                create="/settings/discount/add"
                size="compact"
                columns={columns}
                rowKey="id"
                loading={isLoading}
                data={data?.data}
                params={params}
                rowCount={data?.total}
                setPagination={setParams}
                refetch={refetch}
            />
        </Page>
    )
}

export default Discounts