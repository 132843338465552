import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputPicker, Loader } from "rsuite";
import useStudent from "../../hooks/useStudent";

const SearchStudent = ({ onChange, value }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(null);
  const [companies, isLoading] = useStudent(key);

  const onSearch = (key) => {
    // if (key.length > 1)
    setKey(key);
  };

  return (
    <InputPicker
      block
      data={companies}
      labelKey="name"
      valueKey="id"
      onSearch={onSearch}
      onChange={onChange}
      value={value}
      placeholder={t("pages.student.search")}
      renderMenu={(menu) => {
        if (isLoading) {
          return (
            <div style={{ padding: 10, color: "#999", textAlign: "center" }}>
              <Loader /> {t("general.loading")}
            </div>
          );
        }
        return menu;
      }}
    />
  );
};

export default SearchStudent;
