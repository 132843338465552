import { Minus } from "@rsuite/icons";
import { useTranslation } from "react-i18next";
import { ButtonGroup, Col, Form, IconButton, Row } from "rsuite";
import EnumDropdown from "../../../components/EnumDropdown";
import FileUpload from "../../../components/FileUpload";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { QuestionTypeOptions } from "../../../utils/options";
import QuestionOptions from "./QuestionOptions";

const QuestionComponent = ({
    rowValue = {},
    onChange,
    rowIndex,
    rowError,
    handleMinus,
}) => {
    const { t } = useTranslation();

    const handleChange = (value, name) => {
        onChange(rowIndex, { ...rowValue, [name]: value });
    };

    return (
        <Row gutter={1}>
            <Col xs={24} sm={20} md={22} lg={23} xl={23}>
                <Row gutter={1}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextInput
                            name="questionTypeId"
                            label={t("pages.exam.examQuestions.questionTypeId")}
                            value={rowValue.questionTypeId}
                            error={rowError?.questionTypeId?.errorMessage}
                            onChange={(e) => handleChange(e, "questionTypeId")}
                            data={QuestionTypeOptions}
                            prefix="general.enums.examQuestionType"
                            accepter={EnumDropdown}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextInput
                            name="question"
                            label={t("pages.exam.examQuestions.question")}
                            value={rowValue.question}
                            error={rowError?.question?.errorMessage}
                            rows={3}
                            onChange={(e) => handleChange(e, "question")}
                            accepter={TextArea}
                        />
                    </Col>
                    {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FileUpload
                            name="photo"
                            style={{ width: "100%" }}
                            label={t('pages.exam.examQuestions.photo')}
                            accept="image/png, image/jpeg, image/jpg"
                        />
                    </Col> */}
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Control
                            name="examQuestionOptions"
                            accepter={QuestionOptions}
                            onChange={(e) => handleChange(e, "examQuestionOptions")}
                            fieldError={rowError?.examQuestionOptions}
                            value={rowValue.examQuestionOptions}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} md={2} lg={1} xl={1}>
                <ButtonGroup size="xs" style={{ marginTop: 12 }}>
                    <IconButton onClick={() => handleMinus(rowIndex)} icon={<Minus />} />
                </ButtonGroup>
            </Col>
        </Row>
    );
};

export default QuestionComponent;
