import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../../components/dataTable";
import Page from "../../../../components/layout/Page";
import { useGetEducationLevelsQuery } from "../../../../redux/settingApi";
import { initParams } from "../../../../utils";

const EducationLevels = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState(initParams);
  const { data, isLoading, refetch } = useGetEducationLevelsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const columns = [
    {
      title: t("pages.educationLevel.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 2,
    },
  ];

  return (
    <Page title={t("pages.educationLevel.title")}>
      <DataTable
        create="/settings/education-level/add"
        size="compact"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
      />
    </Page>
  );
};

export default EducationLevels;
