import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Row } from "rsuite";
import QuestionOptionComponent from "./QuestionOptionComponent";


const QuestionOptions = ({ value = [], onChange, fieldError }) => {
    const { t } = useTranslation()
    const errors = fieldError ? fieldError?.array : [];
    const [examQuestionOptions, setExamQuestionOptions] = React.useState(value)

    const handleChangeQuestionOptions = nextQuestionOptions => {
        setExamQuestionOptions(nextQuestionOptions);
        onChange(nextQuestionOptions);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextExamQuestionOptions = [...examQuestionOptions]
        nextExamQuestionOptions[rowIndex] = value
        handleChangeQuestionOptions(nextExamQuestionOptions)
    };

    const addNewQuestionOption = () => {
        const arr = [...examQuestionOptions]
        arr.push({
            optionDescription: null,
            isCorrect: false
        })
        handleChangeQuestionOptions(arr)
    }

    const handleMinus = (index) => {
        const arr = [...examQuestionOptions]
        arr.splice(index, 1)
        handleChangeQuestionOptions(arr)
    }

    return (
        <Row gutter={1}>
            <ButtonToolbar>
                <Button
                    style={{ marginBottom: 10 }}
                    appearance="primary"
                    onClick={addNewQuestionOption}
                >
                    {t("pages.exam.examQuestions.addNewQuestionOption")}
                </Button>
            </ButtonToolbar>
            <Divider />
            {examQuestionOptions.map((rowValue, index) => (
                <>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <QuestionOptionComponent
                            key={index}
                            rowIndex={index}
                            rowValue={rowValue}
                            rowError={errors[index] ? errors[index].object : null}
                            onChange={handleInputChange}
                            handleMinus={handleMinus}
                        />
                    </Col>
                </>
            ))}
        </Row>
    )
}
export default QuestionOptions