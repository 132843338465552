import { Minus } from "@rsuite/icons";
import { useTranslation } from "react-i18next";
import {
  ButtonGroup,
  Col,
  DatePicker,
  Divider,
  IconButton,
  InputNumber,
  Row,
} from "rsuite";
import { isBefore, isAfter } from "rsuite/esm/utils/dateUtils";
import EnumDropdown from "../../../components/EnumDropdown";
import RefDropdown from "../../../components/RefDropdown";
import TextInput from "../../../components/TextInput";
import { paymentTypeEnum } from "../../../utils/enums";
import {
  CurrencyOptions,
  DiscountTypeOptions,
  PaymentTypeOptions,
} from "../../../utils/options";

const StudentEducationComponent = ({
  rowValue = {},
  onChange,
  rowIndex,
  rowError,
  handleMinus,
}) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange(rowIndex, { ...rowValue, [name]: value });
  };

  return (
    <Row gutter={1}>
      <Col xs={24} sm={20} md={22} lg={23} xl={23}>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="softwareId"
              endpoint="settings/software"
              label={t("pages.student.studentEducation.softwareId")}
              cleanable={false}
              accepter={RefDropdown}
              value={rowValue.softwareId}
              error={rowError?.softwareId?.errorMessage}
              onChange={(e) => handleChange(e, "softwareId")}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="educationLevelId"
              endpoint="settings/education-level/list"
              label={t("pages.student.studentEducation.educationLevelId")}
              cleanable={false}
              accepter={RefDropdown}
              value={rowValue.educationLevelId}
              error={rowError?.educationLevelId?.errorMessage}
              onChange={(e) => handleChange(e, "educationLevelId")}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="languageId"
              endpoint="settings/language/list"
              label={t("pages.student.studentEducation.languageId")}
              cleanable={false}
              accepter={RefDropdown}
              value={rowValue.languageId}
              error={rowError?.languageId?.errorMessage}
              onChange={(e) => handleChange(e, "languageId")}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="educationId"
              endpoint="education/list"
              label={t("pages.student.studentEducation.educationId")}
              cleanable={false}
              accepter={RefDropdown}
              query={{
                educationLevelId: rowValue.educationLevelId,
                languageId: rowValue.languageId,
              }}
              skip={!(rowValue.educationLevelId && rowValue.languageId)}
              disabled={!(rowValue.educationLevelId && rowValue.languageId)}
              value={rowValue.educationId}
              error={rowError?.educationId?.errorMessage}
              onChange={(e) => handleChange(e, "educationId")}
            />
          </Col>
          {/* <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="educationExpertiseId"
              endpoint="education-expertise/list"
              label={t("pages.student.studentEducation.educationExpertiseId")}
              cleanable={false}
              accepter={RefDropdown}
              query={{ educationId: rowValue.educationId }}
              skip={!rowValue.educationId}
              disabled={!rowValue.educationId}
              value={rowValue.educationExpertiseId}
              error={rowError?.educationExpertiseId?.errorMessage}
              onChange={(e) => handleChange(e, "educationExpertiseId")}
            />
          </Col> */}
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="advisorId"
              endpoint="teacher/list"
              label={t("pages.student.studentEducation.advisorId")}
              cleanable={false}
              accepter={RefDropdown}
              query={{ educationId: rowValue.educationId }}
              value={rowValue.advisorId}
              error={rowError?.advisorId?.errorMessage}
              onChange={(e) => handleChange(e, "advisorId")}
              skip={!rowValue.educationId}
              disabled={!rowValue.educationId}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="secondAdvisorId"
              endpoint="teacher/list"
              label={t("pages.student.studentEducation.secondAdvisorId")}
              // cleanable={false}
              accepter={RefDropdown}
              // query={{ educationId: rowValue.educationId }}
              value={rowValue.secondAdvisorId}
              error={rowError?.secondAdvisorId?.errorMessage}
              onChange={(e) => handleChange(e, "secondAdvisorId")}
              skip={!rowValue.educationId}
              disabled={!rowValue.educationId}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="registrationDate"
              style={{ width: "100%" }}
              label={t("pages.student.studentEducation.registrationDate")}
              accepter={DatePicker}
              format="dd.MM.yyyy"
              value={rowValue.registrationDate}
              error={rowError?.registrationDate?.errorMessage}
              onChange={(e) => handleChange(e, "registrationDate")}
            //   disabledDate={(date) =>
            //     isAfter(date, new Date().setDate(new Date().getDate()))
            //   }
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="startDate"
              style={{ width: "100%" }}
              label={t("pages.student.studentEducation.startDate")}
              accepter={DatePicker}
              format="dd.MM.yyyy"
              value={rowValue.startDate}
              error={rowError?.startDate?.errorMessage}
              onChange={(e) => handleChange(e, "startDate")}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="agencyId"
              endpoint="agency/list"
              label={t("pages.student.studentEducation.agencyId")}
              cleanable={false}
              accepter={RefDropdown}
              value={rowValue.agencyId}
              error={rowError?.agencyId?.errorMessage}
              onChange={(e) => handleChange(e, "agencyId")}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="agencyRate"
              style={{ width: "100%" }}
              label={t("pages.student.studentEducation.agencyRate")}
              min={0}
              accepter={InputNumber}
              value={rowValue.agencyRate}
              error={rowError?.agencyRate?.errorMessage}
              max={100}
              onChange={(e) => handleChange(e, "agencyRate")}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Divider>
              {t("pages.student.studentEducation.paymentInformation")}
            </Divider>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="price"
              style={{ width: "100%" }}
              label={t("pages.student.studentEducation.price")}
              min={0}
              accepter={InputNumber}
              value={rowValue.price}
              error={rowError?.price?.errorMessage}
              onChange={(e) => handleChange(e, "price")}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="currencyId"
              style={{ width: "100%" }}
              label={t("pages.student.studentEducation.currencyId")}
              data={CurrencyOptions}
              accepter={EnumDropdown}
              value={rowValue.currencyId}
              error={rowError?.currencyId?.errorMessage}
              onChange={(e) => handleChange(e, "currencyId")}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="paymentTypeId"
              label={t("pages.student.studentEducation.paymentTypeId")}
              value={rowValue.paymentTypeId}
              error={rowError?.paymentTypeId?.errorMessage}
              onChange={(e) => handleChange(e, "paymentTypeId")}
              data={PaymentTypeOptions}
              prefix="general.enums.paymentType"
              accepter={EnumDropdown}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="registrationPrice"
              style={{ width: "100%" }}
              label={t("pages.student.studentEducation.registrationPrice")}
              min={0}
              accepter={InputNumber}
              value={rowValue.registrationPrice}
              error={rowError?.registrationPrice?.errorMessage}
              onChange={(e) => handleChange(e, "registrationPrice")}
            />
          </Col>
          {rowValue.paymentTypeId === paymentTypeEnum.installment && (
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="advancePayment"
                style={{ width: "100%" }}
                label={t("pages.student.studentEducation.advancePayment")}
                min={0}
                accepter={InputNumber}
                value={rowValue.advancePayment}
                error={rowError?.advancePayment?.errorMessage}
                onChange={(e) => handleChange(e, "advancePayment")}
              />
            </Col>
          )}
          {rowValue.paymentTypeId === paymentTypeEnum.installment && (
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="totalInstallmentCount"
                style={{ width: "100%" }}
                label={t("pages.student.studentEducation.totalInstallment")}
                min={0}
                accepter={InputNumber}
                value={rowValue.totalInstallmentCount}
                error={rowError?.totalInstallmentCount?.errorMessage}
                onChange={(e) => handleChange(e, "totalInstallmentCount")}
              />
            </Col>
          )}
          {rowValue.paymentTypeId === paymentTypeEnum.installment && (
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="dueDate"
                style={{ width: "100%" }}
                label={t("pages.student.studentEducation.dueDate")}
                accepter={DatePicker}
                format="dd.MM.yyyy"
                disabledDate={(date) =>
                  isBefore(date, new Date().setDate(new Date().getDate()))
                }
                value={rowValue.dueDate}
                error={rowError?.dueDate?.errorMessage}
                onChange={(e) => handleChange(e, "dueDate")}
              />
            </Col>
          )}
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <TextInput
              name="discountId"
              endpoint="settings/discount/list"
              label={t("pages.student.studentEducation.discountId")}
              cleanable={false}
              accepter={RefDropdown}
              value={rowValue.discountId}
              error={rowError?.discountId?.errorMessage}
              onChange={(e) => handleChange(e, "discountId")}
            />
          </Col>
          {rowValue.discountId && (
            <>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <TextInput
                  name="discountTypeId"
                  label={t("pages.student.studentEducation.discountTypeId")}
                  value={rowValue.discountTypeId}
                  error={rowError?.discountTypeId?.errorMessage}
                  onChange={(e) => handleChange(e, "discountTypeId")}
                  data={DiscountTypeOptions}
                  prefix="general.enums.discountType"
                  accepter={EnumDropdown}
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <TextInput
                  name="discountRate"
                  style={{ width: "100%" }}
                  label={t("pages.student.studentEducation.discountRate")}
                  min={0}
                  accepter={InputNumber}
                  value={rowValue.discountRate}
                  error={rowError?.discountRate?.errorMessage}
                  onChange={(e) => handleChange(e, "discountRate")}
                />
              </Col>
            </>
          )}
        </Row>
      </Col>
      <Col xs={24} sm={12} md={2} lg={1} xl={1}>
        <ButtonGroup size="xs" style={{ marginTop: 12 }}>
          <IconButton onClick={() => handleMinus(rowIndex)} icon={<Minus />} />
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default StudentEducationComponent;
