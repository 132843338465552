import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Panel, Row } from "rsuite";
import TeacherDetailComponent from "./TeacherDetailComponent";


const TeacherDetail = ({ value = [], onChange, fieldError }) => {
    const { t } = useTranslation()
    const errors = fieldError?.teacherDetail ? fieldError?.teacherDetail?.array : []
    const [teacherDetail, setTeacherDetail] = React.useState(value)

    const handleTeacherDetail = nextteacherDetail => {
        setTeacherDetail(nextteacherDetail);
        onChange(nextteacherDetail);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextTeacherDetail = [...teacherDetail]
        nextTeacherDetail[rowIndex] = value
        handleTeacherDetail(nextTeacherDetail)
    };

    const addNewQuestion = () => {
        const arr = [...teacherDetail]
        arr.push({
            educationType: null,
            institutionName: null,
        })
        handleTeacherDetail(arr)
    }

    const handleMinus = (index) => {
        const arr = [...teacherDetail]
        arr.splice(index, 1)
        handleTeacherDetail(arr)
    }

    return (
        <Row gutter={1}>
            <ButtonToolbar>
                <Button
                    style={{ marginBottom: 10 }}
                    appearance="primary"
                    onClick={addNewQuestion}
                >
                    {t("pages.teacher.teacherDetail.addNewEducation")}
                </Button>
            </ButtonToolbar>
            <Divider />
            {teacherDetail.map((rowValue, index) => (
                <>
                    <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                        <Panel bordered shaded header={`${t("pages.teacher.teacherDetail.title")} ${index + 1}`} style={{ marginBottom: 8, marginTop: 8 }}>
                            <TeacherDetailComponent
                                key={index}
                                rowIndex={index}
                                rowValue={rowValue}
                                rowError={errors[index] ? errors[index].object : null}
                                onChange={handleInputChange}
                                handleMinus={handleMinus}
                            />
                        </Panel>
                    </Col>
                </>
            ))}
        </Row>
    )
}
export default TeacherDetail