import { Checkbox, Form } from "rsuite";


const CustomCheckboxInput = (props) => {
    const { defaultValue, value, onChange, placeholder, ...rest } = props;
    return (
        <Checkbox value={!value} defaultChecked={defaultValue} checked={value} onChange={onChange} {...rest}>{placeholder}</Checkbox>
    );
}

const CheckboxInput = (props) => {
    const { name, message, label, error, disabled = false, placeholder = null, ...rest } = props
    return (
        <Form.Group className={error ? 'has-error' : ''} style={{ marginBottom: 24 }}>
            <Form.ControlLabel>{label && `${label} :`}</Form.ControlLabel>
            <Form.Control disabled={disabled} name={name} accepter={CustomCheckboxInput} placeholder={placeholder} errorMessage={error} {...rest} />
            {message && <Form.HelpText>{message}</Form.HelpText>}
        </Form.Group>
    )
}

export default CheckboxInput