import React from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Content,
  FlexboxGrid,
  Form,
  Notification,
  Panel,
  Schema,
  useToaster,
} from "rsuite";
import { useTranslation } from "react-i18next";
import { useSearchCertificateQuery } from "../../../redux/settingApi";
import TextInput from "../../../components/TextInput";
import bcuamblem from '../../../assets/bcuamblem.png'
import DataTable from "../../../components/dataTable";
import { api } from "../../../redux/api";
import { useAppDispatch } from "../../../hooks";
const { StringType } = Schema.Types;

const SearchCertificate = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const dispatch = useAppDispatch()
  const toaster = useToaster();

  const model = Schema.Model({
    diplomaNo: StringType().isRequired(
      t("pages.user.login.valid.diplomaNo")
    ),
  });

  const [formValue, setFormValue] = React.useState({
    diplomaNo: ""
  });

  const signIn = () => {
    setLoading(true)
    dispatch(api.endpoints.searchCertificate.initiate({ diplomaNo: formValue.diplomaNo, softwareId: 1 }, { forceRefetch: true })).unwrap().then(res => {
     setLoading(false)
      if (res.status === "success" && res.data?.length > 0) {
        setData(res.data)
      }
      else if (res.status === "success" && res.data?.length === 0) {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.not_found")}
            duration={2000}
          >
            <p>{t("general.error.diploma_not_found")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      }


    }).catch(err => {
      setLoading(false)
    })

  };

  const columns = [
    {
      title: t("pages.student.studentEducation.certificateNo"),
      name: "certificateNo",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.studentEducation.certificateDate"),
      name: "certificateDate",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.surname"),
      name: "surname",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.placeOfBirth"),
      name: "birthPlace",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.student.birthDate"),
      name: "birthDate",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.education.name"),
      name: "educationName",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    }
  ]

  return (
    <div className="show-fake-browser login-page">
      <Container>
        <Content>
          <FlexboxGrid justify="center" style={{ marginBottom: 5 }}>
            <img height={200} src={bcuamblem} alt="logo" />
          </FlexboxGrid>
          <FlexboxGrid justify="center" style={{ marginBottom: 5 }}>
            <h2>Brussels Capital University</h2>
          </FlexboxGrid>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item as={Col} colspan={24} md={4}>
              <Panel
                header={<h3>{t("pages.user.login.searchCertificate")}</h3>}
                bordered
                shaded
              >
                <Form
                  fluid
                  model={model}
                  formValue={formValue}
                  onChange={setFormValue}
                  ref={formRef}
                >
                  <div style={{ borderRadius: 8, inset: 2, zIndex: 522 }}>
                    <Form.Group>
                      <TextInput
                        name="diplomaNo"
                        label={t("pages.user.login.diplomaNo")}
                      />
                    </Form.Group>
                    <Form.Group>
                      <ButtonToolbar>
                        <Button
                          onClick={signIn}
                          type="submit"
                          block
                          loading={loading}
                          appearance="primary"
                        >
                          {t("pages.user.login.search")}
                        </Button>
                      </ButtonToolbar>
                    </Form.Group>
                  </div>
                </Form>
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          {data && data.length > 0 && <FlexboxGrid justify="center" style={{ marginBottom: 5 }}>
            <FlexboxGrid.Item as={Col} colspan={24} md={8}>
              <DataTable
                size="compact"
                columns={columns}
                rowKey="id"
                loading={loading}
                data={data}
                rowCount={data?.length || 0}
                toolbar={null}
                pagination={null}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>}
        </Content>
      </Container>
    </div>
  );
};

export default SearchCertificate;
