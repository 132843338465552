import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, DatePicker, Divider, Form, Grid, Loader, Modal, Notification, Panel, Row, Schema, useToaster } from "rsuite";
import { useUpgradeTermMutation, useGetEducationsByStudentIdQuery, useUpgradeGraduateMutation, } from "../../../../redux/studentApi";
import { getEnumName, initParams } from "../../../../utils";
import { EducationStatusEnum } from "../../../../utils/enums";
import educationImage from '../../../../assets/education.png'
import React from "react";
import TextInput from "../../../../components/TextInput";
const { NumberType, StringType, DateType } = Schema.Types

const EducationList = ({ studentId }) => {
    const { t } = useTranslation();
    const toaster = useToaster()
    const [params, setParams] = useState(initParams);
    const [open, setOpen] = React.useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedData, setData] = React.useState(null)
    const initialValues = {
        studentEducationId: null,
        certificateDate: null,
        certificateNo: null
    }
    const [formValues, setFormValue] = React.useState(initialValues)
    const formRef = React.useRef()
    const { data, isLoading, refetch } = useGetEducationsByStudentIdQuery({ ...params, studentId }, {
        refetchOnMountOrArgChange: true,
    });
    const [update, { isLoading: submitting }] = useUpgradeTermMutation();
    const [updateGraduate, { isLoading: submittingGraduate }] = useUpgradeGraduateMutation();

    const schema = Schema.Model({
        studentEducationId: NumberType().isRequired(t('pages.student.studentEducation.valid.studentEducationId_required')),
        certificateDate: DateType().isRequired(t('pages.student.studentEducation.valid.certificateDate_required')),
        certificateNo: StringType().isRequired(t('pages.student.studentEducation.valid.certificateNo_required')),
    })

    if (isLoading) {
        return <div style={{ padding: 10, color: '#999', textAlign: 'center' }}>
            <Loader /> {t("general.loading")}
        </div>
    }

    const handleModalOpen = (id) => {
        setOpen(true)
        setData(id)
    }
    const handleModalClose = () => {
        setOpen(false)
        setData(null)
    }

    const upgradeTerm = async () => {
        if (!selectedData) {
            toaster.push(
                <Notification
                    closable
                    type="error"
                    header={t("general.error.validation_error")}
                    duration={2000}
                >
                    <p>{t("general.error.validation_error_description")}</p>
                </Notification>,
                { placement: "topEnd" }
            );
            return;
        }
        else {
            await update({ studentEducationId: selectedData })
                .unwrap()
                .then((response) => {
                    toaster.push(
                        <Notification
                            closable
                            type="success"
                            header={t("general.error.success")}
                            duration={2000}
                        >
                            <p>{t("general.error.success_add")}</p>
                        </Notification>,
                        { placement: "topEnd" }
                    );
                    handleModalClose()
                    setData(null)
                    setFormValue({ ...formValues, studentEducationId: null })
                    refetch()
                })
                .catch((error) => {
                    toaster.push(
                        <Notification
                            closable
                            type="error"
                            header={t("general.error.error")}
                            duration={2000}
                        >
                            <p>{error?.data?.description}</p>
                        </Notification>,
                        { placement: "topEnd" }
                    );
                    refetch()
                });
        }
    }

    const graduateStudent = async () => {
        if (!selectedData) {
            toaster.push(
                <Notification
                    closable
                    type="error"
                    header={t("general.error.validation_error")}
                    duration={2000}
                >
                    <p>{t("general.error.validation_error_description")}</p>
                </Notification>,
                { placement: "topEnd" }
            );
            return;
        }
        else {
            await updateGraduate(formValues)
                .unwrap()
                .then((response) => {
                    toaster.push(
                        <Notification
                            closable
                            type="success"
                            header={t("general.error.success")}
                            duration={2000}
                        >
                            <p>{t("general.error.success_add")}</p>
                        </Notification>,
                        { placement: "topEnd" }
                    );
                    closeModal()
                    setData(null)
                    refetch()
                })
                .catch((error) => {
                    toaster.push(
                        <Notification
                            closable
                            type="error"
                            header={t("general.error.error")}
                            duration={2000}
                        >
                            <p>{error?.data?.description}</p>
                        </Notification>,
                        { placement: "topEnd" }
                    );
                    refetch()
                });
        }
    }

    const showGraduateModal = (id) => {
        setShowModal(true)
        setData(id)
        setFormValue({ ...formValues, studentEducationId: id })
    }

    const closeModal = () => {
        setShowModal(false)
        setData(null)
        setFormValue({ ...formValues, studentEducationId: null })
        refetch()
    }

    return (
        <>
            {data?.data?.map((values, index) => (
                <Panel shaded bordered bodyFill style={{ display: 'inline-block' }}>
                    <img src={educationImage} />
                    <Panel header={values?.studentEducation_education.code + " - " + values?.studentEducation_education.name}>
                        {/* <p>
                            {t('pages.student.studentEducation.educationExpertiseId')} : {values?.studentEducation_educationExpertise?.name || "-"}
                        </p> */}
                        <p>
                            {t('pages.student.studentEducation.termId')} : {values?.studentEducation_term?.name}
                        </p>
                        <p>
                            {t('pages.student.studentEducation.advisorId')} : {`${values?.studentEducation_teacher?.name + " " + values?.studentEducation_teacher?.surname}`}
                        </p>
                        <p>
                            {t('pages.student.studentEducation.educationStatusId')} : {t(`general.enums.educationStatus.${getEnumName(EducationStatusEnum, values.educationStatusId)}`)}
                        </p>
                        <p>
                            {t('pages.student.studentEducation.registrationDate')}  : {moment(values.registrationDate).format("DD/MM/YYYY")}
                        </p>
                        <p>
                            {t('pages.student.studentEducation.startDate')} : {moment(values.startDate).format("DD/MM/YYYY")}
                        </p>
                        <p>
                            {t('pages.student.studentEducation.endDate')} : {values.endDate ? moment(values.endDate).format("DD/MM/YYYY") : "-"}
                        </p>
                        <p>
                            {t('pages.student.studentEducation.certificateNo')} : {values.certificateNo ? values.certificateNo : "-"}
                        </p>
                        <p>
                            {t('pages.student.studentEducation.certificateDate')}  :  {values.certificateDate ? moment(values.certificateDate).format("DD/MM/YYYY") : "-"}
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <Divider />
                            <ButtonToolbar
                                style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
                            >
                                <Button
                                    color="green"
                                    appearance="primary"
                                    loading={isLoading}
                                    onClick={() => handleModalOpen(values.id)}
                                    disabled={values.educationStatusId === EducationStatusEnum.graduated || values.educationStatusId === EducationStatusEnum.graduationCase}
                                >
                                    Dönem Geçişi
                                </Button>
                                <Button
                                    color="green"
                                    appearance="primary"
                                    loading={isLoading}
                                    onClick={() => showGraduateModal(values.id)}
                                    disabled={values.educationStatusId === EducationStatusEnum.student || values.educationStatusId === EducationStatusEnum.graduated}
                                >
                                    Mezuniyet İşlemleri
                                </Button>
                            </ButtonToolbar>
                        </p>
                    </Panel>
                </Panel>
            ))
            }
            <Modal size="xs" role="alertdialog" backdrop="static" keyboard={false} open={open} onClose={handleModalClose}>
                <Modal.Header>
                    <Modal.Body>{t("pages.student.studentEducation.upgradeTerm")}</Modal.Body>
                </Modal.Header>
                <Modal.Footer>
                    <Button onClick={() => upgradeTerm()} appearance="primary"
                        loading={submitting}
                    >
                        {t("general.yes")}
                    </Button>
                    <Button onClick={handleModalClose} appearance="subtle"
                        loading={submitting}
                    >
                        {t("general.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop="static" keyboard={false} open={showModal} onClose={closeModal}>
                <Modal.Header>
                    <Modal.Title>{t("pages.student.studentEducation.graduate")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid
                        model={schema}
                        formValue={formValues}
                        onChange={setFormValue}
                        ref={formRef}>
                        <Grid fluid>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextInput
                                        name="certificateNo"
                                        label={t('pages.student.studentEducation.certificateNo')}
                                        style={{ width: '100%' }}
                                    />
                                    <TextInput
                                        name="certificateDate"
                                        label={t('pages.student.studentEducation.certificateDate')}
                                        min={0}
                                        accepter={DatePicker}
                                        format="dd.MM.yyyy"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={graduateStudent} loading={submittingGraduate} appearance="primary">
                        {t("general.save")}
                    </Button>
                    <Button onClick={closeModal} loading={submittingGraduate} appearance="subtle">
                        {t("general.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EducationList