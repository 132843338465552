import React from "react";
import { useTranslation } from "react-i18next";
import { InputPicker } from "rsuite";

const EnumDropdown = ({ onChange, value, labelKey = "name", valueKey = "id", prefix = null, disabled = false, endpoint = null, setDefaultValue = false, setUserDefaultValue = false, placeholder = null, cleanable = true, data = [], ...rest }) => {
    const { t } = useTranslation();

    let newOptions = []
    data.forEach(item => {
        newOptions.push({
            [labelKey]: t(`${prefix ? prefix + "." + item[labelKey] : item[labelKey]}`),
            [valueKey]: item[valueKey]
        })
    })

    return (
        <InputPicker
            disabled={disabled}
            block
            data={newOptions}
            labelKey={labelKey}
            valueKey={valueKey}
            onChange={onChange}
            cleanable={cleanable}
            value={value}
            placeholder={placeholder || t("general.search")}
            renderMenu={menu => {
                return menu;
            }}
        />
    );
}

export default EnumDropdown
