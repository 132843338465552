import { Edit, FileDownload } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import { useGetLessonVideosQuery } from "../../../redux/lessonVideoApi";
import { defaultToolbar, getEnumName, initParams } from "../../../utils";
import { LessonVideoTypesEnum } from "../../../utils/enums";

const LessonVideos = () => {
    const { t } = useTranslation();
    const [params, setParams] = useState(initParams);
    const { data, isLoading, refetch } = useGetLessonVideosQuery(params, {
        refetchOnMountOrArgChange: true,
    });

    const edit = (data) => { };

    const columns = [
        {
            title: t("pages.lessonVideo.educationId"),
            name: "educationId",
            sortable: true,
            align: "left",
            type: "ref",
            fullText: true,
            flexGrow: 1,
            renderCell: (rowData) => <span>{rowData.lessonVideo_education ? rowData.lessonVideo_education?.code + " - " + rowData.lessonVideo_education?.name : ""}</span>,
        },
        {
            title: t("pages.lessonVideo.lessonId"),
            name: "lessonId",
            sortable: true,
            align: "left",
            type: "ref",
            flexGrow: 1,
            renderCell: (rowData) => <span>{rowData.lessonVideo_lesson ? rowData.lessonVideo_lesson?.code + " - " + rowData.lessonVideo_lesson?.name : ""}</span>,
        },
        {
            title: t("pages.lessonVideo.name"),
            name: "name",
            sortable: true,
            align: "left",
            type: "text",
            fullText: true,
            flexGrow: 1,
        },
        {
            title: t("pages.lessonVideo.description"),
            name: "description",
            sortable: true,
            align: "left",
            type: "text",
            fullText: true,
            flexGrow: 1,
        },
        {
            title: t("pages.lessonVideo.videoTypeId"),
            name: "description",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
            renderCell: (rowData) => (
                <>
                    {t(`general.enums.videoType.${getEnumName(LessonVideoTypesEnum, rowData.videoTypeId)}`)}
                </>
            )
        },
        {
            title: t("pages.lessonVideo.isAccessAll"),
            name: "isAccessAll",
            sortable: true,
            align: "left",
            type: "boolean",
            flexGrow: 1,
            renderCell: (rowData) => <span>{rowData.isAccessAll ? t("general.yes") : t("general.no")}</span>,
        },
        {
            title: t("general.table.actions"),
            name: "actions",
            width: 100,
            renderCell: (rowData) => (
                <>
                    <IconButton
                        onClick={() => edit(rowData)}
                        size="xs"
                        icon={<Edit color="orange" />}
                    />
                </>
            ),
        },
    ];

    return (
        <Page title={t("pages.lessonVideo.title")}>
            <DataTable
                create="/lesson-video/add"
                size="compact"
                columns={columns}
                rowKey="id"
                loading={isLoading}
                data={data?.data}
                params={params}
                rowCount={data?.total}
                setPagination={setParams}
                refetch={refetch}
                toolbar={{ ...defaultToolbar, filter: false }}
            />
        </Page>
    );
};

export default LessonVideos;
