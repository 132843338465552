import { Minus } from "@rsuite/icons";
import { useTranslation } from "react-i18next";
import { ButtonGroup, Col, IconButton, Row } from "rsuite";
import CheckboxInput from "../../../components/CheckboxInput";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";

const QuestionOptionComponent = ({
    rowValue = {},
    onChange,
    rowIndex,
    rowError,
    handleMinus,
}) => {
    const { t } = useTranslation();

    const handleChange = (value, name) => {
        onChange(rowIndex, { ...rowValue, [name]: value });
    };

    return (
        <Row gutter={1}>
            <Col xs={24} sm={20} md={22} lg={23} xl={23}>
                <Row gutter={1}>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <CheckboxInput
                            name="isCorrect"
                            label={t('pages.exam.examQuestions.examQuestionOptions.isCorrect')}
                            value={rowValue.isCorrect}
                            error={rowError?.isCorrect?.errorMessage}
                            onChange={(e) => handleChange(e, "isCorrect")}
                        />
                    </Col>
                    <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                        <TextInput
                            name="optionDescription"
                            label={t("pages.exam.examQuestions.examQuestionOptions.optionDescription")}
                            value={rowValue.optionDescription}
                            error={rowError?.optionDescription?.errorMessage}
                            onChange={(e) => handleChange(e, "optionDescription")}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} md={2} lg={1} xl={1}>
                <ButtonGroup size="xs" style={{ marginTop: 12 }}>
                    <IconButton onClick={() => handleMinus(rowIndex)} icon={<Minus />} />
                </ButtonGroup>
            </Col>
        </Row>
    );
};

export default QuestionOptionComponent;
