import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Panel, Row } from "rsuite";
import QuestionComponent from "./QuestionComponent";


const Questions = ({ value = [], onChange, fieldError }) => {
    const { t } = useTranslation()
    const errors = fieldError?.examQuestions ? fieldError?.examQuestions?.array : []
    const [examQuestions, setExamQuestions] = React.useState(value)

    const handleChangeQuestions = nextQuestions => {
        setExamQuestions(nextQuestions);
        onChange(nextQuestions);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextExamQuestions = [...examQuestions]
        nextExamQuestions[rowIndex] = value
        handleChangeQuestions(nextExamQuestions)
    };

    const addNewQuestion = () => {
        const arr = [...examQuestions]
        arr.push({
            questionTypeId: null,
            question: "",
            photo: "",
            examQuestionOptions: [
                {
                    optionDescription: "",
                    isCorrect: false
                }
            ]
        })
        handleChangeQuestions(arr)
    }

    const handleMinus = (index) => {
        const arr = [...examQuestions]
        arr.splice(index, 1)
        handleChangeQuestions(arr)
    }

    return (
        <Row gutter={1}>
            <ButtonToolbar>
                <Button
                    style={{ marginBottom: 10 }}
                    appearance="primary"
                    onClick={addNewQuestion}
                >
                    {t("pages.exam.addNewQuestion")}
                </Button>
            </ButtonToolbar>
            <Divider />
            {examQuestions.map((rowValue, index) => (
                <>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Panel bordered header={`${t("pages.exam.examQuestions.title")} ${index + 1}`} style={{ marginBottom: 8, marginTop: 8 }} shaded>
                            <QuestionComponent
                                key={index}
                                rowIndex={index}
                                rowValue={rowValue}
                                rowError={errors[index] ? errors[index].object : null}
                                onChange={handleInputChange}
                                handleMinus={handleMinus}
                            />
                        </Panel>
                    </Col>
                </>
            ))}
        </Row>
    )
}
export default Questions