import {
  GenderEnum,
  LessonResourceTypesEnum,
  LessonTypesEnum,
  LessonVideoTypesEnum,
  QuestionTypeEnum,
  teacherTypeEnum,
  paymentTypeEnum,
  AgencyTypeEnum,
  DiscountTypeEnum,
  StudentTypeEnum,
  CurrencyEnum,
} from "./enums";

export const GenderOptions = [
  { id: GenderEnum.Erkek, name: "man" },
  { id: GenderEnum.Kadın, name: "woman" },
];

export const LessonTypeOptions = [
  { id: LessonTypesEnum.Required, name: "Zorunlu" },
  { id: LessonTypesEnum.Optional, name: "Opsiyonel" },
];

export const LessonResourceTypesOptions = [
  { id: LessonResourceTypesEnum.Kitap, name: "Kitap" },
  { id: LessonResourceTypesEnum.EKitap, name: "E-Kitap" },
  { id: LessonResourceTypesEnum.Url, name: "Url" },
];

export const LessonVideoTypesOptions = [
  { id: LessonVideoTypesEnum.vimeoEmbed, name: "vimeoEmbed" },
  { id: LessonVideoTypesEnum.url, name: "url" },
  // { id: LessonVideoTypesEnum.Upload, name: "upload" },
];

export const QuestionTypeOptions = [
  { id: QuestionTypeEnum.test, name: "test" },
  { id: QuestionTypeEnum.classic, name: "classic" },
];

export const TeacherTypeOptions = [
  { id: teacherTypeEnum.advisor, name: "advisor" },
  { id: teacherTypeEnum.teacher, name: "teacher" },
  { id: teacherTypeEnum.dr, name: "dr" },
  { id: teacherTypeEnum.doc, name: "doc" },
  { id: teacherTypeEnum.prof, name: "prof" },
  { id: teacherTypeEnum.lecturer, name: "lecturer" },
];

export const PaymentTypeOptions = [
  { id: paymentTypeEnum.advance, name: "advance" },
  { id: paymentTypeEnum.installment, name: "installment" },
];

export const AgencyTypeOptions = [
  { id: AgencyTypeEnum.corporate, name: "corporate" },
  { id: AgencyTypeEnum.indivual, name: "indivual" },
];

export const DiscountTypeOptions = [
  { id: DiscountTypeEnum.amount, name: "amount" },
  { id: DiscountTypeEnum.percent, name: "percent" },
];

export const StudentType = [
  { id: StudentTypeEnum.new, name: "new" },
  { id: StudentTypeEnum.exists, name: "exists" },
];

export const CurrencyOptions = [
  { id: CurrencyEnum.TRY, name: "TRY" },
  { id: CurrencyEnum.USD, name: "USD" },
  { id: CurrencyEnum.EUR, name: "EUR" },
  { id: CurrencyEnum.GBP, name: "GBP" },
  { id: CurrencyEnum.RUB, name: "RUB" },
];
