import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Col,
  DatePicker,
  Divider,
  Form,
  Grid,
  InputNumber,
  Notification,
  Panel,
  Row,
  Schema,
  Uploader,
  useToaster,
} from "rsuite";
import { isAfter } from "rsuite/esm/utils/dateUtils";
import EnumDropdown from "../../../components/EnumDropdown";
import Page from "../../../components/layout/Page";
import ResetButton from "../../../components/ResetButton";
import SearchCountry from "../../../components/SearchCountry";
import SearchStudent from "../../../components/SearchStudent";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useAddStudentMutation } from "../../../redux/studentApi";
import { GenderOptions } from "../../../utils/options";
import StudentEducation from "../components/StudentEducation";

const { StringType, NumberType, ArrayType, BooleanType, DateType, ObjectType } =
  Schema.Types;

const AddStudent = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const history = useNavigate();
  const [createStudent, { isLoading }] = useAddStudentMutation();
  const [isNew, setIsNew] = useState("unset");
  const [file, setSelectedFile] = useState(null);

  const schema = Schema.Model({
    studentId: NumberType(),
    countryId: NumberType().when((schema) => {
      if (!schema.studentId.value) {
        return NumberType().isRequired(
          t("pages.student.valid.countryId_required")
        );
      } else return NumberType();
    }),
    tcKimlikNo: StringType().when((schema) => {
      if (!schema.studentId.value) {
        return (
          StringType()
            .isRequired(t("pages.student.valid.tcKimlikNo_required"))
            // .minLength(11, t("pages.student.valid.tcKimlikNo_minLength"))
            .maxLength(20, t("pages.student.valid.tcKimlikNo_maxLength"))
        );
      } else return StringType();
    }),
    name: StringType().when((schema) => {
      if (!schema.studentId.value) {
        return StringType()
          .isRequired(t("pages.student.valid.name_required"))
          .minLength(1, t("pages.student.valid.name_minLength"))
          .maxLength(64, t("pages.student.valid.name_maxLength"));
      } else return StringType();
    }),
    surname: StringType().when((schema) => {
      if (!schema.studentId.value) {
        return StringType()
          .isRequired(t("pages.student.valid.surname_required"))
          .minLength(1, t("pages.student.valid.surname_minLength"))
          .maxLength(64, t("pages.student.valid.surname_maxLength"));
      } else return StringType();
    }),
    placeOfBirth: StringType().when((schema) => {
      if (!schema.studentId.value) {
        return StringType().maxLength(
          64,
          t("pages.student.valid.placeOfBirth_maxLength")
        );
      } else return StringType();
    }),
    genderId: NumberType().when((schema) => {
      if (!schema.studentId.value) {
        return NumberType().isRequired(
          t("pages.student.valid.genderId_required")
        );
      } else return NumberType();
    }),
    phone: StringType().when((schema) => {
      if (!schema.studentId.value) {
        return StringType()
          .isRequired(t("pages.student.valid.phone_required"))
          .maxLength(32, t("pages.student.valid.phone_maxLength"));
      } else return StringType();
    }),
    email: StringType().when((schema) => {
      if (!schema.studentId.value) {
        return StringType()
          .isRequired(t("pages.student.valid.email_required"))
          .isEmail(t("pages.student.valid.email_invalid"))
          .maxLength(64, t("pages.student.valid.email_maxLength"));
      } else return StringType();
    }),
  });

  const initialValues = {
    studentId: null,
    countryId: null,
    tcKimlikNo: "",
    name: "",
    surname: "",
    fatherName: null,
    motherName: null,
    placeOfBirth: null,
    birthDate: null,
    genderId: null,
    phone: null,
    email: null,
    address: null,
    photoPath: null,
    studentEducation: [
      {
        educationLevelId: null,
        languageId: null,
        educationId: null,
        advisorId: null,
        price: null,
        paymentTypeId: null,
        advancePayment: null,
        currencyId: 1,
        registrationPrice: null,
        totalInstallmentCount: null,
        dueDate: null,
        startDate: null,
        agencyId: null,
        agencyRate: null,
        discountRate: null,
        discountTypeId: null,
        discountId: null,
      },
    ],
  };

  const [formValues, setFormValue] = React.useState(initialValues);
  const [formError, setFormError] = React.useState({});

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onerror = reject;
      fileReader.onload = function () {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const handleFileInputChange = (e) => {
    if (e.length > 0) {
      let file = e[0];
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(e[0].name)[1];
      getBase64(file.blobFile)
        .then((result) => {
          setSelectedFile({ file: result, ext: ext });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    await createStudent({ ...formValues, file })
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        history("/student/list");
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  return (
    <Page title={t("pages.student.add_title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        onCheck={setFormError}
        ref={formRef}
      >
        <Grid fluid>
          {isNew === "unset" ? (
            <Row gutter={16}>
              <ButtonToolbar
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <Button
                  appearance="primary"
                  onClick={() => setIsNew(true)}
                  loading={isLoading}
                  type="button"
                >
                  Yeni Öğrenci
                </Button>
                <Button
                  appearance="primary"
                  onClick={() => setIsNew(false)}
                  loading={isLoading}
                  type="button"
                >
                  Var Olan Öğrenci
                </Button>
              </ButtonToolbar>
            </Row>
          ) : null}
          {isNew === false ? (
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <TextInput
                  name="studentId"
                  label={t("pages.student.studentGrade.studentId")}
                  accepter={SearchStudent}
                />
              </Col>
            </Row>
          ) : isNew === true ? (
            <Row gutter={16}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: 10 }}
              >
                <Panel bordered header={t("pages.student.general")} shaded>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <TextInput
                      name="countryId"
                      label={t("pages.student.countryId")}
                      accepter={SearchCountry}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <TextInput
                      name="tcKimlikNo"
                      label={t("pages.student.tcKimlikNo")}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <TextInput name="name" label={t("pages.student.name")} />
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <TextInput
                      name="surname"
                      label={t("pages.student.surname")}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <TextInput
                      name="genderId"
                      label={t("pages.student.genderId")}
                      accepter={EnumDropdown}
                      data={GenderOptions}
                      prefix="general.enums.gender"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <TextInput
                      name="fatherName"
                      label={t("pages.student.fatherName")}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <TextInput
                      name="motherName"
                      label={t("pages.student.motherName")}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <TextInput
                      name="placeOfBirth"
                      label={t("pages.student.placeOfBirth")}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <TextInput
                      name="birthDate"
                      style={{ width: "100%" }}
                      label={t("pages.student.birthDate")}
                      accepter={DatePicker}
                      format="dd.MM.yyyy"
                      disabledDate={(date) =>
                        isAfter(date, new Date().setDate(new Date().getDate()))
                      }
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    {/* <TextInput name="phone" label={t("pages.student.phone")} /> */}
                    <TextInput
                      name="phone"
                      style={{ width: "100%" }}
                      label={t("pages.student.phone")}
                      accepter={InputNumber}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <TextInput name="email" label={t("pages.student.email")} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <TextInput
                      name="address"
                      label={t("pages.student.address")}
                      rows={3}
                      accepter={TextArea}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Uploader
                      onChange={handleFileInputChange}
                      draggable
                      disabled={file && file.file}
                      autoUpload={false}
                      onRemove={() => setSelectedFile({ file: null })}
                      accept="image/jpg,image/png,image/svg+xml,image/jpeg"
                    >
                      <div
                        style={{
                          height: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>
                          Dosya Yüklemek için sürükleyin veya tıklayın
                        </span>
                      </div>
                    </Uploader>
                  </Col>
                </Panel>
              </Col>
            </Row>
          ) : null}
          {isNew !== "unset" && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginBottom: 10 }}
            >
              <Panel bordered header={t("pages.student.education")} shaded>
                <Form.Control
                  name="studentEducation"
                  accepter={StudentEducation}
                  fieldError={formError}
                />
              </Panel>
            </Col>
          )}
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={isLoading}
              disabled={isNew === "unset"}
            >
              {t("general.save")}
            </Button>
            <ResetButton
              onOk={() => setFormValue(initialValues)}
              disabled={isLoading || isNew === "unset"}
            />
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Page>
  );
};

export default AddStudent;
