import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useTranslation } from "react-i18next";
import {
    useGetTeacherByIdQuery,
    useUpdateTeacherMutation,
} from "../../../redux/teacherApi";
import EnumDropdown from "../../../components/EnumDropdown";
import { GenderOptions, LessonTypeOptions, TeacherTypeOptions } from "../../../utils/options";
import ResetButton from "../../../components/ResetButton";
import {
    Button,
    ButtonToolbar,
    Col,
    Divider,
    Form,
    Grid,
    Panel,
    Notification,
    Row,
    Schema,
    useToaster,
    Input,
    Loader,
} from "rsuite";
import SearchCountry from "../../../components/SearchCountry";
import FileUpload from "../../../components/FileUpload";
const { StringType, NumberType } = Schema.Types;

const UpdateTeacher = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const formRef = React.useRef();
    const toaster = useToaster();
    const { id } = useParams();

    const [updateLesson, { isLoading }] = useUpdateTeacherMutation();

    const { data: teacher, isLoading: fetchLoading } = useGetTeacherByIdQuery({ id: id });

    const [formValues, setFormValue] = React.useState(teacher);
    const [formError, setFormError] = React.useState({});

    React.useEffect(() => {
        if (teacher?.data) {
            setFormValue(teacher?.data)
        }
    }, [teacher?.data])

    const schema = Schema.Model({
        name: StringType().isRequired(
            t("pages.lessons.valid.education_required")
        )
    });

    const onSubmit = async () => {
        if (!formRef.current.check()) {
            toaster.push(
                <Notification
                    closable
                    type="error"
                    header={t("general.error.validation_error")}
                    duration={2000}
                >
                    <p>{t("general.error.validation_error_description")}</p>
                </Notification>,
                { placement: "topEnd" }
            );
            return;
        }
        await updateLesson(formValues)
            .unwrap()
            .then((response) => {
                toaster.push(
                    <Notification
                        closable
                        type="success"
                        header={t("general.error.success")}
                        duration={2000}
                    >
                        <p>{t("general.error.success_add")}</p>
                    </Notification>,
                    { placement: "topEnd" }
                );
                history("/teacher/list");
            })
            .catch((error) => {
                toaster.push(
                    <Notification
                        closable
                        type="error"
                        header={t("general.error.error")}
                        duration={2000}
                    >
                        <p>{error?.data?.description}</p>
                    </Notification>,
                    { placement: "topEnd" }
                );
            });
    };
    return (
        <Page title={teacher?.data?.name}>
            <Form
                fluid
                model={schema}
                formValue={formValues}
                onCheck={setFormError}
                onChange={(formVal) => setFormValue(formVal)}
                ref={formRef}
            >
                <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="countryId"
                                label={t("pages.management.countryId")}
                                accepter={SearchCountry}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="teacherTypeId"
                                label={t("pages.teacher.teacherTypeId")}
                                accepter={EnumDropdown}
                                data={TeacherTypeOptions}
                                prefix="general.enums.teacherType"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="tcKimlikNo"
                                label={t("pages.teacher.tcKimlikNo")}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="genderId"
                                label={t("pages.teacher.genderId")}
                                accepter={EnumDropdown}
                                data={GenderOptions}
                                prefix="general.enums.gender"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput name="name" label={t("pages.teacher.name")} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput name="surname" label={t("pages.teacher.surname")} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput name="phone" label={t("pages.teacher.phone")} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput name="phone2" label={t("pages.teacher.phone2")} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput name="email" label={t("pages.teacher.email")} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput name="email2" label={t("pages.teacher.email2")} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextInput
                                name="address"
                                label={t("pages.teacher.address")}
                                rows={3}
                                accepter={TextArea}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            {/* <FileUpload
                                name="files"
                                style={{ width: "100%" }}
                                label={t("pages.teacher.photo")}
                                accept="image/png, image/jpeg, image/jpg"
                            /> */}
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <Divider />
                    <ButtonToolbar
                        style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
                    >
                        <Button
                            color="green"
                            appearance="primary"
                            onClick={onSubmit}
                            loading={isLoading}
                        >
                            {t("general.save")}
                        </Button>
                        <ResetButton
                            onOk={() => setFormValue(teacher)}
                            disabled={isLoading}
                        />
                    </ButtonToolbar>
                </Form.Group>
            </Form>
            {fetchLoading && <Loader vertical backdrop center size="lg" content={`${t("general.loading")}...`} />}
        </Page>
    );
};
export default UpdateTeacher;
