import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  Notification,
  Row,
  Schema,
  useToaster,
} from "rsuite";
import EnumDropdown from "../../../components/EnumDropdown";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import FileUpload from "../../../components/FileUpload";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useAddLessonVideoMutation } from "../../../redux/lessonVideoApi";
import { LessonVideoTypesEnum } from "../../../utils/enums";
import { LessonVideoTypesOptions } from "../../../utils/options";
import CheckboxInput from "../../../components/CheckboxInput";
import { useState } from "react";

const { StringType, NumberType, BooleanType } = Schema.Types;

const AddLessonVideo = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const history = useNavigate();
  const [createLessonVideo, { isLoading }] = useAddLessonVideoMutation();
  const uploader = React.useRef();
  const [loading, setLoading] = useState(false);

  const schema = Schema.Model({
    educationId: NumberType().isRequired(
      t("pages.lessonVideo.valid.educationId_required")
    ),
    videoTypeId: NumberType().isRequired(
      t("pages.lessonVideo.valid.videoTypeId_required")
    ),
    name: StringType()
      .isRequired(t("pages.lessonVideo.valid.name_required"))
      .minLength(1, t("pages.lessonVideo.valid.name_minLength"))
      .maxLength(256, t("pages.lessonVideo.valid.name_maxLength")),
    description: StringType().maxLength(
      512,
      t("pages.lessonVideo.valid.description_maxLength")
    ),
    isAccessAll: BooleanType(),
    resource: StringType().when((schema) => {
      if (schema.videoTypeId.value !== LessonVideoTypesEnum.Upload) {
        return StringType().isRequired(
          t("pages.lessonVideo.valid.resource_required")
        );
      } else {
        if (!schema.resource.value || schema.resource.value === "") {
          return StringType();
        }
        return StringType();
      }
    }),
  });

  const initialValues = {
    educationId: null,
    lessonId: null,
    videoTypeId: null,
    name: null,
    description: null,
  };

  const [formValues, setFormValue] = React.useState(initialValues);

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    if (formValues.videoTypeId === LessonVideoTypesEnum.Upload) {
      uploader.current.start();
    } else {
      await createLessonVideo(formValues)
        .unwrap()
        .then((response) => {
          toaster.push(
            <Notification
              closable
              type="success"
              header={t("general.error.success")}
              duration={2000}
            >
              <p>{t("general.error.success_add")}</p>
            </Notification>,
            { placement: "topEnd" }
          );
          history("/lesson-video/list");
        })
        .catch((error) => {
          toaster.push(
            <Notification
              closable
              type="error"
              header={t("general.error.error")}
              duration={2000}
            >
              <p>{error?.data?.description}</p>
            </Notification>,
            { placement: "topEnd" }
          );
        });
    }
  };

  return (
    <Page title={t("pages.lessonVideo.add_title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={(formVal) => setFormValue(formVal)}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="educationId"
                endpoint="education/list"
                label={t("pages.lessonVideo.educationId")}
                cleanable={false}
                accepter={RefDropdown}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="lessonId"
                endpoint="lesson/list"
                label={t("pages.lessonVideo.lessonId")}
                cleanable={false}
                accepter={RefDropdown}
                query={{ educationId: formValues.educationId }}
                skip={!formValues.educationId}
                disabled={!formValues.educationId}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="videoTypeId"
                label={t("pages.lessonVideo.videoTypeId")}
                accepter={EnumDropdown}
                data={LessonVideoTypesOptions}
                prefix="general.enums.videoType"
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <CheckboxInput
                name="isAccessAll"
                label={t("pages.lessonVideo.isAccessAll")}
              />
            </Col>
            <Col xs={24} sm={12} md={24} lg={24} xl={24}>
              <TextInput name="name" label={t("pages.lessonVideo.name")} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <TextInput
                name="description"
                label={t("pages.lessonVideo.description")}
                rows={5}
                accepter={TextArea}
              />
            </Col>
            {formValues.videoTypeId &&
              formValues.videoTypeId === LessonVideoTypesEnum.Upload && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FileUpload
                    action="https://admin.bcuni.eu/api/lesson-video/add"
                    name="files"
                    method="POST"
                    autoUpload={false}
                    multiple={false}
                    style={{ width: "100%" }}
                    label={t("pages.lessonVideo.files")}
                    accept="video/*"
                    withCredentials={true}
                    innerRef={uploader}
                    data={formValues}
                    onUpload={(file) => {
                      setLoading(true);
                    }}
                    onSuccess={(response, file) => {
                      setLoading(false);
                      toaster.push(
                        <Notification
                          closable
                          type="success"
                          header={t("general.error.success")}
                          duration={2000}
                        >
                          <p>{t("general.error.success_add")}</p>
                        </Notification>,
                        { placement: "topEnd" }
                      );
                      history("/lesson-video/list");
                    }}
                    onError={() => {
                      setLoading(false);
                      toaster.push(
                        <Notification
                          closable
                          type="error"
                          header={t("general.error.error")}
                          duration={2000}
                        >
                          <p>Hata</p>
                        </Notification>,
                        { placement: "topEnd" }
                      );
                    }}
                  />
                </Col>
              )}
            {formValues.videoTypeId &&
              formValues.videoTypeId !== LessonVideoTypesEnum.Upload && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="resource"
                    label={t("pages.lessonVideo.resource")}
                    rows={5}
                    accepter={TextArea}
                  />
                </Col>
              )}
          </Row>
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={loading}
            >
              {t("general.save")}
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Page>
  );
};

export default AddLessonVideo;
