import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Panel, Row } from "rsuite";
import LessonComponent from "./lessonComponent";

const LessonResource = ({ value = [], onChange, fieldError }) => {
  const { t } = useTranslation();
  const errors = fieldError?.orderMemo ? fieldError?.orderMemo?.array : [];
  const [lessonResources, setLessonResources] = React.useState(value);

  const handleChangeResource = (nextLessonResources) => {
    setLessonResources(nextLessonResources);
    onChange(nextLessonResources);
  };

  const handleInputChange = (rowIndex, value) => {
    const nextLessonResources = [...lessonResources];
    nextLessonResources[rowIndex] = value;
    handleChangeResource(nextLessonResources);
  };

  const addNewResource= () => {
    const arr = [...lessonResources];
    arr.push({
      forAll: false,
      description: "",
    });
    handleChangeResource(arr);
  };

  const handleMinus = (index) => {
    const arr = [...lessonResources];
    arr.splice(index, 1);
    handleChangeResource(arr);
  };

  return (
    <Row gutter={1}>
      <Panel bordered shaded>
        <Button
          style={{ marginBottom: 10 }}
          appearance="primary"
          onClick={addNewResource}
        >
          {t("pages.lessons.add_new_lesson_resource")}
        </Button>
        {lessonResources.map((rowValue, index) => (
          <LessonComponent
            key={index}
            rowIndex={index}
            rowValue={rowValue}
            rowError={errors[index] ? errors[index].object : null}
            onChange={handleInputChange}
            handleMinus={handleMinus}
          />
        ))}
      </Panel>
    </Row>
  );
};
export default LessonResource;
