import { api } from "./api";
const apiUrl = "https://admin.bcuni.eu/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addCompany: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/company/add-company`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getCompanyList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/company/get-company-list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    addCompanyAddress: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/company-address/add-company-address`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getCompanyAddressList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/company-address/get-company-address-by-company`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddCompanyMutation,
  useGetCompanyListQuery,

  useAddCompanyAddressMutation,
  useGetCompanyAddressListQuery,
} = extendedApi;
