import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, DatePicker, Divider, Form, Grid, InputNumber, Notification, Row, Schema, useToaster } from "rsuite";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import ResetButton from "../../../components/ResetButton";
import SearchStudent from "../../../components/SearchStudent";
import TextInput from "../../../components/TextInput";
import { useDirectGraduateMutation } from "../../../redux/studentApi";

const { StringType, NumberType, DateType } = Schema.Types

const DirectGraduate = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const toaster = useToaster();
    const [create, { isLoading }] = useDirectGraduateMutation()

    const schema = Schema.Model({
        studentId: NumberType().isRequired(t('pages.student.studentGrade.valid.studentId_required')),
        studentEducationId: NumberType().isRequired(t('pages.student.studentGrade.valid.studentEducationId_required')),
        certificateNo: StringType().isRequired(t('pages.student.studentGrade.valid.certificateNo_required')),
        certificateDate: DateType().isRequired(t('pages.student.studentGrade.valid.certificateDate_required')),
        startGrade: NumberType().isRequired(t('pages.student.studentGrade.valid.grade_required')),
        endGrade: NumberType().isRequired(t('pages.student.studentGrade.valid.grade_required')),
    })

    const initialValues = {
        studentId: null,
        studentEducationId: null,
        certificateNo: null,
        certificateDate: null,
        startGrade: null,
        endGrade: null
    }

    const [formValues, setFormValue] = React.useState(initialValues)

    const onSubmit = async () => {
        if (!formRef.current.check()) {
            toaster.push(<Notification closable type="error" header={t('general.error.validation_error')} duration={2000}><p>{t('general.error.validation_error_description')}</p></Notification>, { placement: "topEnd" })
            return;
        }
        await create(formValues).unwrap().then((response) => {
            toaster.push(<Notification closable type="success" header={t('general.error.success')} duration={2000}><p>{t('general.error.success_add')}</p></Notification>, { placement: "topEnd" })
        }
        ).catch((error) => {
            toaster.push(<Notification closable type="error" header={t('general.error.error')} duration={2000}><p>{error?.data?.description}</p></Notification>, { placement: "topEnd" })
        });
    }


    return (
        <Page title={t('pages.student.studentGrade.directGraduate')}>
            <Form fluid
                model={schema}
                formValue={formValues}
                onChange={setFormValue}
                ref={formRef}>
                <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="studentId"
                                label={t("pages.student.studentGrade.studentId")}
                                accepter={SearchStudent}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="studentEducationId"
                                endpoint="student/education"
                                label={t("pages.student.studentGrade.studentEducationId")}
                                accepter={RefDropdown}
                                skip={!formValues.studentId}
                                query={{ studentId: formValues.studentId }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="certificateNo"
                                label={t("pages.student.studentGrade.certificateNo")}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="certificateDate"
                                style={{ width: "100%" }}
                                label={t("pages.student.studentGrade.certificateDate")}
                                accepter={DatePicker}
                                format="dd.MM.yyyy"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="startGrade"
                                style={{ width: "100%" }}
                                label={t('pages.student.studentGrade.startGrade')}
                                min={0}
                                accepter={InputNumber}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="endGrade"
                                style={{ width: "100%" }}
                                label={t('pages.student.studentGrade.endGrade')}
                                min={0}
                                accepter={InputNumber}
                            />
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <Divider />
                    <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button color="green" appearance="primary" onClick={onSubmit} loading={isLoading}>
                            {t("general.save")}
                        </Button>
                        <ResetButton onOk={() => setFormValue(initialValues)} disabled={isLoading} />
                    </ButtonToolbar>
                </Form.Group>
            </Form>

        </Page>
    )
}

export default DirectGraduate