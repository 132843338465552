import { useMemo } from "react";
import { useGetStudentElasticsQuery } from "../redux/studentApi";
import { initParams } from "../utils";
const useStudent = (key) => {
  let params = key ? { ...initParams, name: key } : { ...initParams };
  const { data, isLoading } = useGetStudentElasticsQuery(
    { ...params }
    // { skip: !key }
  );
  const students = data?.data || [];
  let tmp = [];
  for (let i = 0; i < students.length; i++) {
    const e = students[i];
    tmp.push({ ...e, name: e.tcKimlikNo + " - " + e.name + " " + e.surname });
  }
  return [tmp, isLoading];
  // return useMemo(() => ({ companies, isLoading }), [companies, isLoading])
};

export default useStudent;
