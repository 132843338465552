import { Check, FileDownload } from "@rsuite/icons";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Grid, IconButton, Notification, Row, Schema, useToaster } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import SearchStudent from "../../../components/SearchStudent";
import TextInput from "../../../components/TextInput";
import { useGetExpiredPaymentListQuery } from "../../../redux/studentApi";
import { defaultToolbar, getEnumName, initParams } from "../../../utils";
import { StudentTaskStatusEnum } from "../../../utils/enums";
const { NumberType } = Schema.Types

const StudentExpiredPayments = () => {
    const { t } = useTranslation()
    const formRef = React.useRef()
    const [params, setParams] = useState(initParams)
    const initialValues = {
        studentId: null,
        studentEducationId: null
    }
    const [formValues, setFormValue] = React.useState(initialValues)

    const { data, isLoading, refetch } = useGetExpiredPaymentListQuery({ ...params, studentEducationId: formValues.studentEducationId }, {
        refetchOnMountOrArgChange: true
    });

    const schema = Schema.Model({
        studentId: NumberType(),
        studentEducationId: NumberType(),
    })

    const columns = [
        {
            title: t("pages.student.studentExpiredPayment.studentName"),
            name: "statusId",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
            fullText: true,
            renderCell: (rowData) => (
                <>
                    {rowData.studentPaymentDetail_studentPayment?.studentPayment_studentEducation?.studentEducation_student.tcKimlikNo + " - " + rowData.studentPaymentDetail_studentPayment?.studentPayment_studentEducation?.studentEducation_student.name + " " + rowData.studentPaymentDetail_studentPayment?.studentPayment_studentEducation?.studentEducation_student.surname}
                </>
            )
            // renderCell: (rowData) => (
            //     <>
            //         {t(`general.enums.studentTask.${getEnumName(StudentTaskStatusEnum, rowData.statusId)}`)}
            //     </>
            // )
        },
        {
            title: t("pages.student.studentExpiredPayment.studentPhone"),
            name: "statusId",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
            renderCell: (rowData) => (
                <>
                    {rowData.studentPaymentDetail_studentPayment?.studentPayment_studentEducation?.studentEducation_student.phone}
                </>
            )
        },
        {
            title: t("pages.student.studentExpiredPayment.studentEducation"),
            name: "statusId",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
            renderCell: (rowData) => (
                <>
                    {rowData.studentPaymentDetail_studentPayment?.studentPayment_studentEducation?.studentEducation_education.code + " - " + rowData.studentPaymentDetail_studentPayment?.studentPayment_studentEducation?.studentEducation_education.name}
                </>
            )
        },
        {
            title: t("pages.student.studentExpiredPayment.dueDate"),
            name: "statusId",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
            renderCell: (rowData) => (
                <>
                    {rowData.dueDate ? moment(rowData.dueDate).format("DD/MM/YYYY") : ""}
                </>
            )
        },
        {
            title: t("pages.student.studentExpiredPayment.installmentNo"),
            name: "installmentNo",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1
        },
        {
            title: t("pages.student.studentExpiredPayment.price"),
            name: "price",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
    ];

    return (
        <Page title={t('pages.student.studentExpiredPayment.title')}>
            <Form fluid
                model={schema}
                formValue={formValues}
                onChange={setFormValue}
                ref={formRef}>
                {/* <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="studentId"
                                label={t("pages.student.studentGrade.studentId")}
                                accepter={SearchStudent}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="studentEducationId"
                                endpoint="student/education"
                                label={t("pages.student.studentGrade.studentEducationId")}
                                accepter={RefDropdown}
                                skip={!formValues.studentId}
                                query={{ studentId: formValues.studentId }}
                            />
                        </Col>
                    </Row>
                </Grid> */}
                <DataTable
                    size="compact"
                    columns={columns}
                    rowKey="id"
                    loading={isLoading}
                    data={data?.data}
                    params={params}
                    rowCount={data?.total}
                    setPagination={setParams}
                    toolbar={{ ...defaultToolbar, filter: false }}
                />
            </Form>
        </Page>
    )

}

export default StudentExpiredPayments