import { useAppSelector } from "../hooks";
import { getCurrentUser } from "../redux/authSlice";

const useUser = () => {
    const currentUser = useAppSelector(getCurrentUser)
    console.log(currentUser)
    return [currentUser];
    // return useMemo(() => ({ companies, isLoading }), [companies, isLoading])
};

export default useUser