import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  Notification,
  Row,
  Schema,
  useToaster,
} from "rsuite";
import { useAddEducationLevelMutation } from "../../../../redux/settingApi";
import Page from "../../../../components/layout/Page";
import ResetButton from "../../../../components/ResetButton";
import TextInput from "../../../../components/TextInput";

const { StringType } = Schema.Types;

const AddEducationLevel = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const [createEducationLevel, { isLoading }] = useAddEducationLevelMutation();

  const schema = Schema.Model({
    name: StringType()
      .isRequired(t("pages.educationLevel.valid.name_required"))
      .minLength(1, t("pages.educationLevel.valid.name_minLength"))
      .maxLength(256, t("pages.educationLevel.valid.name_maxLength")),
  });

  const initialValues = {
    name: "",
  };

  const [formValues, setFormValue] = React.useState(initialValues);

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    await createEducationLevel(formValues)
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  return (
    <Page title={t("pages.educationLevel.add_title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="name" label={t("pages.educationLevel.name")} />
            </Col>
          </Row>
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={isLoading}
            >
              {t("general.save")}
            </Button>
            <ResetButton
              onOk={() => setFormValue(initialValues)}
              disabled={isLoading}
            />
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Page>
  );
};

export default AddEducationLevel;
