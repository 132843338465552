import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, FlexboxGrid, Form, Steps } from "rsuite"
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';

const StepForm = ({ children, steps = [], onSubmit, isLoading, initalValues, schema, formRef, setFormValue }) => {
    const { t } = useTranslation()
    const itemCount = steps?.length || 0

    const [step, setStep] = React.useState(0);

    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > itemCount ? 1 : nextStep);
    };
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);
    const getTitle = (index) => {
        if (step > index) {
            return t("general.finished")
        }
        else if (step < index) {
            return t("general.waiting")
        }
        else return t("general.inProgress")
    }

    return (
        <>
            <FlexboxGrid justify="center">
                <FlexboxGrid.Item as={Col} md={12} xs={24}>
                    <Steps current={step}>
                        {steps.map(({ description }, index) =>
                            <Steps.Item icon={index === step ? <SpinnerIcon pulse style={{ fontSize: '2em' }} /> : undefined} key={index} title={getTitle(index)} description={description} />
                        )}
                        {/* <Steps.Item title={t("general.finished")} description="General Information" />
                        <Steps.Item title={t("general.inProgress")} description="Other Information  " /> */}
                    </Steps>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <Form fluid
                model={schema}
                formValue={initalValues}
                onChange={setFormValue}
                ref={formRef}>
                {children?.map((m) =>
                    Number(m.key) === step && m)}
            </Form>
            <hr />
            <ButtonGroup style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={onPrevious} disabled={isLoading || step === 0}>
                    {t("general.previous")}
                </Button>
                {step !== itemCount - 1 && <Button onClick={onNext} disabled={step === itemCount - 1}>
                    {t("general.next")}
                </Button>}
                {step === itemCount - 1 &&
                    <Button onClick={onSubmit} loading={isLoading}>
                        {t("general.save")}
                    </Button>}
            </ButtonGroup>
        </>)
}

export default StepForm