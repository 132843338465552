import { Edit } from "@rsuite/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Form, Grid, IconButton, InputNumber, Modal, Notification, Row, Schema, useToaster } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import TextInput from "../../../components/TextInput";
import SearchStudent from "../../../components/SearchStudent";
import { useGetGraduateListByStudentEducationIdQuery, useUpdateStudentGradeMutation } from "../../../redux/studentApi";
import { defaultToolbar, initParams } from "../../../utils";
const { NumberType } = Schema.Types

const StudentGrade = () => {
    const { t } = useTranslation()
    const formRef = React.useRef()
    const toaster = useToaster()
    const [params, setParams] = useState(initParams)
    const [showModal, setShowModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const initialValues = {
        studentId: null,
        studentEducationId: null,
        lessonId: null,
        grade: null
    }
    const [formValues, setFormValue] = React.useState(initialValues)

    const { data, isLoading, refetch } = useGetGraduateListByStudentEducationIdQuery({ ...params, studentEducationId: formValues.studentEducationId }, {
        refetchOnMountOrArgChange: true,
        skip: !formValues.studentEducationId
    });
    const [updateStudentGrade, { isLoading: submitting }] = useUpdateStudentGradeMutation();

    const schema = Schema.Model({
        studentId: NumberType().isRequired(t('pages.student.studentGrade.valid.studentId_required')),
        studentEducationId: NumberType().isRequired(t('pages.student.studentGrade.valid.studentEducationId_required')),
        lessonId: NumberType().isRequired(t('pages.student.studentGrade.valid.lessonId_required')),
        grade: NumberType().isRequired(t('pages.student.studentGrade.valid.grade_required')).min(0, t('pages.student.studentGrade.valid.grade_min')).max(100, t('pages.student.studentGrade.valid.grade_max'))
    })

    const columns = [
        {
            title: t("general.table.actions"),
            name: "actions",
            width: 100,
            renderCell: (rowData) => (
                <>
                    <IconButton
                        onClick={() => edit(rowData)}
                        size="xs"
                        icon={<Edit color="orange" />}
                    />
                </>
            ),
        },
        {
            title: t("pages.student.studentGrade.lessonCode"),
            name: "studentLesson_lesson.code",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.studentGrade.lessonName"),
            name: "studentLesson_lesson.name",
            sortable: true,
            align: "left",
            fullText: true,
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.studentGrade.grade"),
            name: "grade",
            sortable: true,
            align: "left",
            type: "number",
            flexGrow: 1,
        },
        {
            title: t("pages.student.studentGrade.letterGrade"),
            name: "letterGrade",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        }
    ];

    const edit = (data) => {
        setFormValue({ ...formValues, lessonId: data.lessonId, id: data.id, grade: data.grade })
        setSelectedRow(data)
        setShowModal(true)
    }

    const closeModal = () => {
        setFormValue({ ...formValues, grade: null, lessonId: null, id: null })
        setSelectedRow(null)
        setShowModal(false)
        refetch()
    }

    const onSubmit = async () => {
        if (!formRef.current.check()) {
            toaster.push(<Notification closable type="error" header={t('general.error.validation_error')} duration={2000}><p>{t('general.error.validation_error_description')}</p></Notification>, { placement: "topEnd" })
            return;
        }
        await updateStudentGrade(formValues).unwrap().then((response) => {
            toaster.push(<Notification closable type="success" header={t('general.error.success')} duration={2000}><p>{t('general.error.success_add')}</p></Notification>, { placement: "topEnd" })
            closeModal()
            refetch()
        }
        ).catch((error) => {
            toaster.push(<Notification closable type="error" header={t('general.error.error')} duration={2000}><p>{error?.data?.description}</p></Notification>, { placement: "topEnd" })
        });
    }

    return (
        <Page title={t('pages.student.studentGrade.title')}>
            <Form fluid
                model={schema}
                formValue={formValues}
                onChange={setFormValue}
                ref={formRef}>
                <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            {/* <TextInput
                                name="studentId"
                                endpoint="student/list"
                                label={t("pages.student.studentGrade.studentId")}
                                accepter={RefDropdown}
                                clearable={false}
                            /> */}
                            <TextInput
                                name="studentId"
                                label={t("pages.student.studentGrade.studentId")}
                                accepter={SearchStudent}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="studentEducationId"
                                endpoint="student/education"
                                label={t("pages.student.studentGrade.studentEducationId")}
                                accepter={RefDropdown}
                                skip={!formValues.studentId}
                                query={{ studentId: formValues.studentId }}
                            />
                        </Col>
                    </Row>
                </Grid>
                <DataTable
                    size="compact"
                    columns={columns}
                    rowKey="id"
                    loading={isLoading}
                    data={data?.data}
                    params={params}
                    rowCount={data?.total}
                    setPagination={setParams}
                    toolbar={{ ...defaultToolbar, filter: false }}
                />
                <Modal backdrop="static" keyboard={false} open={showModal} onClose={closeModal}>
                    <Modal.Header>
                        <Modal.Title>{selectedRow && selectedRow.studentLesson_lesson.code + " - " + selectedRow.studentLesson_lesson.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextInput
                                        name="grade"
                                        label={t('pages.student.studentGrade.grade')}
                                        min={0}
                                        accepter={InputNumber}
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onSubmit} loading={submitting} appearance="primary">
                            {t("general.save")}
                        </Button>
                        <Button onClick={closeModal} loading={submitting} appearance="subtle">
                            {t("general.cancel")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        </Page>
    )

}

export default StudentGrade