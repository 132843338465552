import { Edit } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Form, Grid, IconButton, Row } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import TextInput from "../../../components/TextInput";
import { useGetEducationsQuery } from "../../../redux/education";
import { defaultToolbar, initParams } from "../../../utils";

const Educations = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const formRef = React.useRef();
  const initialValues = {
    name: null,
  };
  const [formValues, setFormValue] = React.useState(initialValues);
  const { data, isLoading, refetch } = useGetEducationsQuery(params, {
    refetchOnMountOrArgChange: true,
  });
  const history = useNavigate();
  const edit = (data) => {
    history(`/educations/${data.id}`);
  };

  const columns = [
    {
      title: t("pages.education.code"),
      name: "code",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.education.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.education.language"),
      name: "languageId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 2,
      renderCell: (rowData) => <span>{rowData.education_language?.name}</span>,
    },
    {
      title: t("pages.education.term"),
      name: "termId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 2,
      renderCell: (rowData) => <span>{rowData.education_term?.name}</span>,
    },
    {
      title: t("pages.education.education_level"),
      name: "educationLevelId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 2,
      renderCell: (rowData) => (
        <span>{rowData.education_educationLevel?.name}</span>
      ),
    },
    {
      title: t("pages.education.description"),
      name: "description",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },

    {
      title: t("general.table.actions"),
      name: "actions",
      width: 100,
      renderCell: (rowData) => (
        <IconButton
          onClick={() => edit(rowData)}
          size="xs"
          icon={<Edit color="orange" />}
        />
      ),
    },
  ];

  return (
    <Page title={t("pages.education.title")}>
      <Form
        fluid
        // model={schema}
        formValue={formValues}
        onChange={(e) => setParams({ ...params, ...e })}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="name"
                endpoint="education/list"
                label={t("pages.education.name")}
                clearable={false}
                secondField="surname"
              />
            </Col>
          </Row>
        </Grid>
      </Form>
      <DataTable
        create="/educations/add"
        size="compact"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
        toolbar={{ ...defaultToolbar, filter: false }}
      />
    </Page>
  );
};

export default Educations;
