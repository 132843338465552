import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  Notification,
  Row,
  Schema,
  useToaster,
} from "rsuite";
import EnumDropdown from "../../../components/EnumDropdown";
import FileUpload from "../../../components/FileUpload";
import Page from "../../../components/layout/Page";
import ResetButton from "../../../components/ResetButton";
import SearchCountry from "../../../components/SearchCountry";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useAddTeacherMutation } from "../../../redux/teacherApi";
import { GenderOptions, TeacherTypeOptions } from "../../../utils/options";
import TeacherConsulted from "../components/TeacherConsulted";
import TeacherDetail from "../components/TeacherDetail";

const { StringType, NumberType, ArrayType, ObjectType } = Schema.Types;

const AddTeacher = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const formRef = React.useRef();
  const toaster = useToaster();
  const [createTeacher, { isLoading }] = useAddTeacherMutation();

  const schema = Schema.Model({
    countryId: NumberType().isRequired(
      t("pages.teacher.valid.countryId_required")
    ),
    teacherTypeId: NumberType().isRequired(
      t("pages.teacher.valid.teacherTypeId_required")
    ),
    tcKimlikNo: StringType()
      .isRequired(t("pages.teacher.valid.tcKimlikNo_required"))
      .minLength(11, t("pages.teacher.valid.tcKimlikNo_minLength"))
      .maxLength(11, t("pages.teacher.valid.tcKimlikNo_maxLength")),
    genderId: NumberType().isRequired(
      t("pages.teacher.valid.genderId_required")
    ),
    name: StringType()
      .isRequired(t("pages.teacher.valid.name_required"))
      .minLength(1, t("pages.teacher.valid.name_minLength"))
      .maxLength(64, t("pages.teacher.valid.name_maxLength")),
    surname: StringType()
      .isRequired(t("pages.teacher.valid.surname_required"))
      .minLength(1, t("pages.teacher.valid.surname_minLength"))
      .maxLength(64, t("pages.teacher.valid.surname_maxLength")),
    phone: StringType()
      .isRequired(t("pages.teacher.valid.phone_required"))
      .minLength(1, t("pages.teacher.valid.phone_minLength"))
      .maxLength(64, t("pages.teacher.valid.phone_maxLength")),
    phone2: StringType()
      .minLength(1, t("pages.teacher.valid.phone_minLength"))
      .maxLength(64, t("pages.teacher.valid.phone_maxLength")),
    email: StringType()
      .isRequired(t("pages.teacher.valid.email_required"))
      .isEmail(t("pages.teacher.valid.email_invalid"))
      .maxLength(64, t("pages.teacher.valid.email_maxLength")),
    email2: StringType()
      .isEmail(t("pages.teacher.valid.email_invalid"))
      .maxLength(64, t("pages.teacher.valid.email_maxLength")),
    address: StringType().maxLength(
      256,
      t("pages.teacher.valid.address_maxLength")
    ),
    teacherDetail: ArrayType().of(
      ObjectType().shape({
        educationType: StringType().maxLength(
          256,
          t("pages.teacher.teacherDetail.valid.educationType_maxLength")
        ),
        institutionName: StringType().maxLength(
          256,
          t("pages.teacher.teacherDetail.valid.institutionName_maxLength")
        ),
      })
    ),
  });

  const initialValues = {
    countryId: null,
    teacherTypeId: null,
    tcKimlikNo: "",
    genderId: null,
    name: "",
    surname: "",
    phone: "",
    phone2: "",
    email: "",
    email2: "",
    address: "",
    teacherDetail: [],
  };

  const [formValues, setFormValue] = React.useState(initialValues);
  const [formError, setFormError] = React.useState({});

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    await createTeacher(formValues)
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        history("/teacher/list");
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  return (
    <Page title={t("pages.teacher.add_title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onCheck={setFormError}
        onChange={setFormValue}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="countryId"
                label={t("pages.management.countryId")}
                accepter={SearchCountry}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="teacherTypeId"
                label={t("pages.teacher.teacherTypeId")}
                accepter={EnumDropdown}
                data={TeacherTypeOptions}
                prefix="general.enums.teacherType"
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="tcKimlikNo"
                label={t("pages.teacher.tcKimlikNo")}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="genderId"
                label={t("pages.teacher.genderId")}
                accepter={EnumDropdown}
                data={GenderOptions}
                prefix="general.enums.gender"
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="name" label={t("pages.teacher.name")} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="surname" label={t("pages.teacher.surname")} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="phone" label={t("pages.teacher.phone")} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="phone2" label={t("pages.teacher.phone2")} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="email" label={t("pages.teacher.email")} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="email2" label={t("pages.teacher.email2")} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <TextInput
                name="address"
                label={t("pages.teacher.address")}
                rows={3}
                accepter={TextArea}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FileUpload
                name="files"
                style={{ width: "100%" }}
                label={t("pages.teacher.photo")}
                accept="image/png, image/jpeg, image/jpg"
              />
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Divider>{t("pages.teacher.consultedEducations")}</Divider>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Control
                name="teacherConsulted"
                accepter={TeacherConsulted}
                fieldError={formError}
              />
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Divider>{t("pages.teacher.educations")}</Divider>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Control
                name="teacherDetail"
                accepter={TeacherDetail}
                fieldError={formError}
              />
            </Col>
          </Row>
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={isLoading}
            >
              {t("general.save")}
            </Button>
            <ResetButton
              onOk={() => setFormValue(initialValues)}
              disabled={isLoading}
            />
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Page>
  );
};

export default AddTeacher;
