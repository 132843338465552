import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  Notification,
  Row,
  Schema,
  useToaster,
} from "rsuite";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useAddLessonTaskMutation } from "../../../redux/lessonTaskApi";

const { StringType, NumberType } = Schema.Types;

const AddLessonTask = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const history = useNavigate();
  const [createLessonTask, { isLoading }] = useAddLessonTaskMutation();

  const schema = Schema.Model({
    educationId: NumberType().isRequired(
      t("pages.lessonTask.valid.educationId_required")
    ),
    lessonId: NumberType().isRequired(
      t("pages.lessonTask.valid.lessonId_required")
    ),
    name: StringType()
      .isRequired(t("pages.lessonTask.valid.name_required"))
      .minLength(1, t("pages.lessonTask.valid.name_minLength"))
      .maxLength(256, t("pages.lessonTask.valid.name_maxLength")),
    description: StringType().maxLength(
      512,
      t("pages.lessonTask.valid.description_maxLength")
    ),
  });

  const initialValues = {
    educationId: null,
    lessonId: null,
    name: null,
    description: null,
  };

  const [formValues, setFormValue] = React.useState(initialValues);

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    await createLessonTask(formValues)
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        history("/lesson-task/list");
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  return (
    <Page title={t("pages.lessonTask.add_title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={(formVal) => setFormValue(formVal)}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="educationId"
                endpoint="education/list"
                label={t("pages.lessonTask.educationId")}
                cleanable={false}
                accepter={RefDropdown}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="lessonId"
                endpoint="lesson/list"
                label={t("pages.lessonTask.lessonId")}
                cleanable={false}
                accepter={RefDropdown}
                query={{ educationId: formValues.educationId }}
                skip={!formValues.educationId}
                disabled={!formValues.educationId}
              />
            </Col>
            <Col xs={24} sm={12} md={24} lg={24} xl={24}>
              <TextInput name="name" label={t("pages.lessonTask.name")} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <TextInput
                name="description"
                label={t("pages.lessonTask.description")}
                rows={5}
                accepter={TextArea}
              />
            </Col>
          </Row>
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={isLoading}
            >
              {t("general.save")}
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Page>
  );
};

export default AddLessonTask;
