import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  InputNumber,
  Loader,
  Notification,
  Row,
  Schema,
  useToaster,
} from "rsuite";
import {
  useGetEducationsByIdQuery,
  useUpdateEducationMutation,
} from "../../../redux/education";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import ResetButton from "../../../components/ResetButton";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
const { StringType, NumberType } = Schema.Types;

const UpdateEducation = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const formRef = React.useRef();
  const toaster = useToaster();
  const { id } = useParams();

  const [updateEducation, { isLoading }] = useUpdateEducationMutation();
  const { data: education, isLoading: fetchLoading } = useGetEducationsByIdQuery({ id: id });
  const [formValues, setFormValue] = React.useState(education?.data);

  React.useEffect(() => {
    if (education?.data) {
      setFormValue(education?.data)
    }
  }, [education?.data])


  const schema = Schema.Model({
    code: StringType()
      .isRequired(t("pages.education.valid.code_required"))
      .minLength(1, t("pages.education.valid.code_minLength"))
      .maxLength(16, t("pages.education.valid.code_maxLength")),

    name: StringType()
      .isRequired(t("pages.education.valid.name_required"))
      .minLength(1, t("pages.education.valid.name_minLength"))
      .maxLength(256, t("pages.education.valid.name_maxLength")),
    totalCredit: NumberType()
      .isRequired(t("pages.education.valid.totalCredit"))
      .min(0, t("pages.education.valid.totalCredit_min")),
    languageId: NumberType().isRequired(t("pages.education.valid.language")),
    termId: NumberType().isRequired(t("pages.education.valid.term")),
    educationLevelId: NumberType().isRequired(
      t("pages.education.valid.educationLevel")
    ),
  });

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    await updateEducation(formValues)
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        history("/educations/list");
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  return (
    <Page title={education?.data.name}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        ref={formRef}
        disabled={fetchLoading}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="code" label={t("pages.education.code")} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="name" label={t("pages.education.name")} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="totalCredit"
                style={{ width: "100%" }}
                label={t("pages.education.totalCredit")}
                min={0}
                accepter={InputNumber}
                disabled={true}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TextInput
                name="languageId"
                endpoint="settings/language/list"
                label={t("pages.education.language")}
                cleanable={false}
                accepter={RefDropdown}
                disabled={true}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TextInput
                name="termId"
                endpoint="settings/term/list"
                label={t("pages.education.term")}
                cleanable={false}
                accepter={RefDropdown}
                disabled={true}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TextInput
                name="educationLevelId"
                endpoint="settings/education-level/list"
                label={t("pages.education.education_level")}
                cleanable={false}
                accepter={RefDropdown}
                disabled={true}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <TextInput
                name="description"
                label={t("pages.education.description")}
                rows={3}
                accepter={TextArea}
              />
            </Col>
          </Row>
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={isLoading}
            >
              {t("general.save")}
            </Button>
            <ResetButton
              onOk={() => setFormValue(education)}
              disabled={isLoading}
            />
          </ButtonToolbar>
        </Form.Group>
      </Form>
      {fetchLoading && <Loader vertical backdrop center size="lg" content={`${t("general.loading")}...`} />}
    </Page>
  );
};

export default UpdateEducation;
