import { Edit } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconButton } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import { useGetLessonTasksQuery } from "../../../redux/lessonTaskApi";
import { defaultToolbar, initParams } from "../../../utils";

const LessonTasks = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const { data, isLoading, refetch } = useGetLessonTasksQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const history = useNavigate();
  const edit = (data) => {
    history(`/lesson-task/${data.id}`);
  };

  const columns = [
    {
      title: t("pages.lessonTask.educationId"),
      name: "educationId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>{rowData.lessonTask_lesson.lesson_education.name}</span>
      ),
    },
    {
      title: t("pages.lessonTask.lessonId"),
      name: "lessonId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {rowData.lessonTask_lesson.code +
            " - " +
            rowData.lessonTask_lesson.name}
        </span>
      ),
    },
    {
      title: t("pages.lessonTask.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      fullText: true,
      flexGrow: 1,
    },
    {
      title: t("pages.lessonTask.description"),
      name: "description",
      sortable: true,
      fullText: true,
      align: "left",
      type: "text",
      flexGrow: 2,
    },
    {
      title: t("general.table.actions"),
      name: "actions",
      width: 100,
      renderCell: (rowData) => (
        <IconButton
          onClick={() => edit(rowData)}
          size="xs"
          icon={<Edit color="orange" />}
        />
      ),
    },
  ];

  return (
    <Page title={t("pages.lessonTask.title")}>
      <DataTable
        create="/lesson-task/add"
        size="compact"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
        toolbar={{ ...defaultToolbar, filter: false }}
      />
    </Page>
  );
};

export default LessonTasks;
