import { api } from "./api";
const apiUrl = "https://admin.bcuni.eu/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addTeacher: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/teacher/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getTeachers: builder.query({
      query: (data) => ({
        url: `${apiUrl}/teacher/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    updateTeacher: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/teacher/update`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getTeacherById: builder.query({
      query: (data) => ({
        url: `${apiUrl}/teacher/by-id`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddTeacherMutation,
  useGetTeachersQuery,
  useUpdateTeacherMutation,
  useGetTeacherByIdQuery,
} = extendedApi;
