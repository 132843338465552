import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  Notification,
  Row,
  Schema,
  Uploader,
  useToaster,
} from "rsuite";
import EnumDropdown from "../../../components/EnumDropdown";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import FileUpload from "../../../components/FileUpload";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useAddLessonDocumentMutation } from "../../../redux/lessonDocumentApi";
import { LessonVideoTypesEnum } from "../../../utils/enums";
import { LessonVideoTypesOptions } from "../../../utils/options";
import CheckboxInput from "../../../components/CheckboxInput";

const { StringType, NumberType, BooleanType } = Schema.Types;

const AddLessonDocument = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const history = useNavigate();
  const [createLessonDocument, { isLoading }] = useAddLessonDocumentMutation();
  const uploader = React.useRef();
  const [loading, setLoading] = React.useState(false);

  const schema = Schema.Model({
    educationId: NumberType().isRequired(
      t("pages.lessonDocument.valid.educationId_required")
    ),
    // name: StringType()
    //     .isRequired(t("pages.lessonDocument.valid.name_required"))
    //     .minLength(1, t("pages.lessonDocument.valid.name_minLength"))
    //     .maxLength(256, t("pages.lessonDocument.valid.name_maxLength")),
    description: StringType().maxLength(
      512,
      t("pages.lessonDocument.valid.description_maxLength")
    ),
    isAccessAll: BooleanType(),
  });

  const initialValues = {
    educationId: null,
    lessonId: null,
    name: null,
    description: null,
  };

  const [formValues, setFormValue] = React.useState(initialValues);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onerror = reject;
      fileReader.onload = function () {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const handleFileInputChange = (e) => {
    if (e.length > 0) {
      let file = e[0];
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(e[0].name)[1];
      getBase64(file.blobFile)
        .then((result) => {
          setFormValue({
            ...formValues,
            description: e[0].name,
            file: result,
            ext: ext,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    //uploader.current.start()
    await createLessonDocument(formValues)
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        history("/lesson-document/list");
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  return (
    <Page title={t("pages.lessonDocument.add_title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={(formVal) => setFormValue(formVal)}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="educationId"
                endpoint="education/list"
                label={t("pages.lessonDocument.educationId")}
                cleanable={false}
                accepter={RefDropdown}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="lessonId"
                endpoint="lesson/list"
                label={t("pages.lessonDocument.lessonId")}
                cleanable={false}
                accepter={RefDropdown}
                query={{ educationId: formValues.educationId }}
                skip={!formValues.educationId}
                disabled={!formValues.educationId}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <CheckboxInput
                name="isAccessAll"
                label={t("pages.lessonDocument.isAccessAll")}
              />
            </Col>
            {/* <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                            <TextInput
                                name="name"
                                label={t("pages.lessonDocument.name")}
                            />
                        </Col> */}
            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextInput
                                name="description"
                                label={t("pages.lessonDocument.description")}
                                rows={5}
                                accepter={TextArea}
                            />
                        </Col> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {/* <FileUpload
                                action="https://admin.bcuni.eu/api/lesson-document/add"
                                name="files"
                                method="POST"
                                autoUpload={false}
                                multiple={false}
                                style={{ width: "100%" }}
                                label={t('pages.lessonDocument.files')}
                                withCredentials={true}
                                innerRef={uploader}
                                data={formValues}
                                onUpload={file => {
                                    setLoading(true);
                                }}
                                onSuccess={(response, file) => {
                                    setLoading(false);
                                    toaster.push(
                                        <Notification
                                            closable
                                            type="success"
                                            header={t("general.error.success")}
                                            duration={2000}
                                        >
                                            <p>{t("general.error.success_add")}</p>
                                        </Notification>,
                                        { placement: "topEnd" }
                                    );
                                    history("/lesson-document/list");
                                }}
                                onError={() => {
                                    setLoading(false);
                                    toaster.push(
                                        <Notification
                                            closable
                                            type="error"
                                            header={t("general.error.error")}
                                            duration={2000}
                                        >
                                            <p>Hata</p>
                                        </Notification>,
                                        { placement: "topEnd" }
                                    );
                                }}
                            /> */}
              <Uploader
                onChange={handleFileInputChange}
                draggable
                disabled={formValues.file}
                autoUpload={false}
                onRemove={() =>
                  setFormValue({ ...formValues, file: null, ext: null })
                }
              >
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>Dosya Yüklemek için sürükleyin veya tıklayın</span>
                </div>
              </Uploader>
            </Col>
          </Row>
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={isLoading}
            >
              {t("general.save")}
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Page>
  );
};

export default AddLessonDocument;
