import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useTranslation } from "react-i18next";
import {
  useGetLessonsByIdQuery,
  useUpdateLessonMutation,
} from "../../../redux/lesson";
import EnumDropdown from "../../../components/EnumDropdown";
import LessonResource from "../components/lessonResources";
import { LessonTypeOptions } from "../../../utils/options";
import ResetButton from "../../../components/ResetButton";
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  Panel,
  Notification,
  Row,
  Schema,
  useToaster,
  Input,
  Loader,
} from "rsuite";
const { StringType, NumberType } = Schema.Types;

const UpdateLesson = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const formRef = React.useRef();
  const toaster = useToaster();
  const { id } = useParams();

  const [updateLesson, { isLoading }] = useUpdateLessonMutation();

  const { data: lesson, isLoading: fetchLoading } = useGetLessonsByIdQuery({ id: id });

  const [formValues, setFormValue] = React.useState(lesson);
  const [formError, setFormError] = React.useState({});

  React.useEffect(() => {
    if (lesson?.data) {
      setFormValue(lesson?.data)
    }
  }, [lesson?.data])

  const schema = Schema.Model({
    educationId: NumberType().isRequired(
      t("pages.lessons.valid.education_required")
    ),
    termId: NumberType().isRequired(t("pages.lessons.valid.term_required")),
    lessonTypeId: NumberType().isRequired(
      t("pages.lessons.valid.lesson_type_required")
    ),
    name: StringType()
      .isRequired(t("pages.lessons.valid.name_required"))
      .minLength(1, t("pages.lessons.valid.name_minLength"))
      .maxLength(256, t("pages.lessons.valid.name_maxLength")),

    credit: NumberType().isRequired(t("pages.lessons.valid.credit_required")),

    // lessonResource: ArrayType().of(
    //   ObjectType().shape({
    //     lessonTypeId: NumberType().isRequired(
    //       t("pages.lessonsResources.valid.lesson_type_required")
    //     ),
    //     name: StringType()
    //       .isRequired(t("pages.lessonsResources.valid.name_required"))
    //       .minLength(1, t("pages.lessonsResources.valid.name_minLength"))
    //       .maxLength(256, t("pages.lessonsResources.valid.name_maxLength")),
    //   })
    // ),
  });

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    await updateLesson(formValues)
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        history("/lessons/list");
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };
  return (
    <Page title={lesson?.data?.name}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onCheck={setFormError}
        onChange={(formVal) => setFormValue(formVal)}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Panel
              bordered
              header={t("pages.lessons.general_lesson_data")}
              shaded
            >
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <TextInput name="name" label={t("pages.lessons.name")} />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <TextInput
                  name="educationId"
                  endpoint="education/list"
                  label={t("pages.lessons.education")}
                  cleanable={false}
                  accepter={RefDropdown}
                  disabled={true}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <TextInput
                  name="termId"
                  endpoint="settings/term/list"
                  label={t("pages.lessons.term")}
                  cleanable={false}
                  accepter={RefDropdown}
                  disabled={true}
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <TextInput
                  name="lessonTypeId"
                  label={t("pages.lessons.lesson_type")}
                  accepter={EnumDropdown}
                  data={LessonTypeOptions}
                />
              </Col>

              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <TextInput
                  name="credit"
                  label={t("pages.lessons.credit")}
                  accepter={Input}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextInput
                  name="description"
                  label={t("pages.lessons.description")}
                  rows={3}
                  accepter={TextArea}
                />
              </Col>
            </Panel>
          </Row>
          <Row>
            <Divider>{t("pages.lessons.lesson_resources")}</Divider>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Control
                name="lessonResources"
                accepter={LessonResource}
                fieldError={formError}
              />
            </Col>
          </Row>
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={isLoading}
            >
              {t("general.save")}
            </Button>
            <ResetButton
              onOk={() => setFormValue(lesson)}
              disabled={isLoading}
            />
          </ButtonToolbar>
        </Form.Group>
      </Form>
      {fetchLoading && <Loader vertical backdrop center size="lg" content={`${t("general.loading")}...`} />}
    </Page>
  );
};
export default UpdateLesson;
