import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Form, Grid, Input, Notification, Row, Schema, useToaster } from "rsuite";
import CheckboxInput from "../../../components/CheckboxInput";
import Page from "../../../components/layout/Page";
import ResetButton from "../../../components/ResetButton";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { useAddDiscountMutation } from "../../../redux/settingApi";

const { StringType } = Schema.Types

const AddDiscount = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const toaster = useToaster();
    const [createDiscount, { isLoading }] = useAddDiscountMutation()

    const schema = Schema.Model({
        name: StringType().isRequired(t('pages.discount.valid.name_required')).minLength(1, t("pages.discount.valid.name_minLength")).maxLength(64, t("pages.discount.valid.name_maxLength")),
        description: StringType()
    })

    const initialValues = {
        name: "",
        description: "",
    }

    const [formValues, setFormValue] = React.useState(initialValues)

    const onSubmit = async () => {
        if (!formRef.current.check()) {
            toaster.push(<Notification closable type="error" header={t('general.error.validation_error')} duration={2000}><p>{t('general.error.validation_error_description')}</p></Notification>, { placement: "topEnd" })
            return;
        }
        await createDiscount(formValues).unwrap().then((response) => {
            toaster.push(<Notification closable type="success" header={t('general.error.success')} duration={2000}><p>{t('general.error.success_add')}</p></Notification>, { placement: "topEnd" })
        }
        ).catch((error) => {
            toaster.push(<Notification closable type="error" header={t('general.error.error')} duration={2000}><p>{error?.data?.description}</p></Notification>, { placement: "topEnd" })
        });
    }


    return (
        <Page title={t('pages.discount.add_title')}>
            <Form fluid
                model={schema}
                formValue={formValues}
                onChange={setFormValue}
                ref={formRef}>
                <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="name"
                                label={t('pages.discount.name')}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextInput
                                name="  "
                                label={t('pages.discount.description')}
                                rows={3}
                                accepter={TextArea}
                            />
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <Divider />
                    <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button color="green" appearance="primary" onClick={onSubmit} loading={isLoading}>
                            {t("general.save")}
                        </Button>
                        <ResetButton onOk={() => setFormValue(initialValues)} disabled={isLoading} />
                    </ButtonToolbar>
                </Form.Group>
            </Form>

        </Page>
    )
}

export default AddDiscount