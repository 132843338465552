import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  Notification,
  Row,
  Schema,
  useToaster,
} from "rsuite";
import Page from "../../../components/layout/Page";
import ResetButton from "../../../components/ResetButton";
import TextInput from "../../../components/TextInput";
import { useAddCompanyMutation } from "../../../redux/companyApi";

const { StringType, BooleanType } = Schema.Types;

const AddCompany = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const [createCompany, { isLoading }] = useAddCompanyMutation();

  const schema = Schema.Model({
    name: StringType().isRequired(t("pages.company.valid.name_required")),
    shortName: StringType(),
    taxNo: StringType().isRequired(t("pages.company.valid.taxNo_required")),
    taxOffice: StringType().isRequired(
      t("pages.company.valid.taxOffice_required")
    ),
    mersis: StringType()
      .pattern(/^[0-9]+$/, t("general.error.only_number"))
      .minLength(16, t("pages.company.valid.mersis_invalid_length"))
      .maxLength(16, t("pages.company.valid.mersis_invalid_length")),
    eInvoiceUser: BooleanType(t("pages.company.valid.eInvoiceUser_invalid")),
    eDispatchUser: BooleanType(t("pages.company.valid.eDispatchUser_invalid")),
  });

  const initialValues = {
    name: "",
    shortName: "",
    taxNo: "",
    taxOffice: "",
    mersis: "",
    eInvoiceUser: false,
    eDispatchUser: false,
  };

  const [formValues, setFormValue] = React.useState(initialValues);

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    await createCompany(formValues)
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  return (
    <Page title={t("pages.company.add_title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput name="name" label={t("pages.company.name")} />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TextInput
                name="shortName"
                label={t("pages.company.shortName")}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TextInput
                name="taxOffice"
                label={t("pages.company.taxOffice")}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TextInput name="taxNo" label={t("pages.company.taxNo")} />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TextInput name="mersis" label={t("pages.company.mersis")} />
            </Col>
          </Row>
        </Grid>
        <Form.Group>
          <Divider />
          <ButtonToolbar
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={onSubmit}
              loading={isLoading}
            >
              {t("general.save")}
            </Button>
            <ResetButton
              onOk={() => setFormValue(initialValues)}
              disabled={isLoading}
            />
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Page>
  );
};

export default AddCompany;
