import { Edit } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Grid, IconButton, Row } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import { useGetLessonsQuery } from "../../../redux/lesson";
import { defaultToolbar, initParams } from "../../../utils";
import { LessonTypeOptions } from "../../../utils/options";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/TextInput";

const Lessons = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const initialValues = {
    name: null,
  };
  const [formValues, setFormValue] = React.useState(initialValues);
  const formRef = React.useRef();
 
  const { data, isLoading, refetch } = useGetLessonsQuery(params, {
    refetchOnMountOrArgChange: true,
  });
  const history = useNavigate();
  const edit = (data) => {
    history(`/lessons/${data.id}`);
  };

  const columns = [
    {
      title: t("pages.lessons.code"),
      name: "code",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.lessons.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.lessons.education"),
      name: "educationId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => <span>{rowData.lesson_education.name}</span>,
    },
    {
      title: t("pages.lessons.term"),
      name: "termId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => <span>{rowData.lesson_term.name}</span>,
    },
    {
      title: t("pages.lessons.lesson_type"),
      name: "lessonTypeId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {LessonTypeOptions.find((f) => f.id === rowData.lessonTypeId)?.name}
        </span>
      ),
    },
    {
      title: t("pages.lessons.credit"),
      name: "credit",
      sortable: true,
      align: "left",
      type: "number",
      flexGrow: 1,
    },

    {
      title: t("pages.lessons.description"),
      name: "description",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 2,
    },

    {
      title: t("general.table.actions"),
      name: "actions",
      width: 100,
      renderCell: (rowData) => (
        <IconButton
          onClick={() => edit(rowData)}
          size="xs"
          icon={<Edit color="orange" />}
        />
      ),
    },
  ];

  return (
    <Page title={t("pages.lessons.title")}>
      <Form
        fluid
        // model={schema}
        formValue={formValues}
        onChange={(e) => setParams({ ...params, ...e })}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="name"
                endpoint="lesson/list"
                label={t("pages.lessons.name")}
                clearable={false}
              />
            </Col>
          </Row>
        </Grid>
      </Form>
      <DataTable
        create="/lessons/add"
        size="compact"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
        toolbar={{ ...defaultToolbar, filter: false }}
      />
    </Page>
  );
};

export default Lessons;
