import { Minus } from "@rsuite/icons";
import { useTranslation } from "react-i18next";
import { ButtonGroup, Col, IconButton, Row } from "rsuite";
import TextInput from "../../../components/TextInput";

const TeacherDetailComponent = ({
    rowValue = {},
    onChange,
    rowIndex,
    rowError,
    handleMinus,
}) => {
    const { t } = useTranslation();

    const handleChange = (value, name) => {
        onChange(rowIndex, { ...rowValue, [name]: value });
    };

    return (
        <Row gutter={1}>
            <Col xs={24} sm={20} md={22} lg={23} xl={23}>
                <Row gutter={1}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextInput
                            name="institutionName"
                            label={t("pages.teacher.teacherDetail.institutionName")}
                            value={rowValue.institutionName}
                            error={rowError?.institutionName?.errorMessage}
                            onChange={(e) => handleChange(e, "institutionName")}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextInput
                            name="educationType"
                            label={t("pages.teacher.teacherDetail.educationType")}
                            value={rowValue.educationType}
                            error={rowError?.educationType?.errorMessage}
                            onChange={(e) => handleChange(e, "educationType")}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} md={2} lg={1} xl={1}>
                <ButtonGroup size="xs" style={{ marginTop: 12 }}>
                    <IconButton onClick={() => handleMinus(rowIndex)} icon={<Minus />} />
                </ButtonGroup>
            </Col>
        </Row>
    );
};

export default TeacherDetailComponent;
