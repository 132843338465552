import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Form, Grid, Notification, Row, Schema, useToaster } from "rsuite";
import CheckboxInput from "../../../components/CheckboxInput";
import Page from "../../../components/layout/Page";
import ResetButton from "../../../components/ResetButton";
import SearchCity from "../../../components/SearchCity";
import SearchCompany from "../../../components/SearchCompany";
import SearchCountry from "../../../components/SearchCountry";
import SearchProvince from "../../../components/SearchProvince";
import TextInput from "../../../components/TextInput";
import { useAddCompanyAddressMutation } from "../../../redux/companyApi";

const { StringType, BooleanType, NumberType } = Schema.Types

const AddCompany = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const toaster = useToaster();
    const [createCompanyAddress, { isLoading }] = useAddCompanyAddressMutation()

    const schema = Schema.Model({
        companyId: NumberType().isRequired(t("pages.companyAddress.valid.company_required")),
        isDefault: BooleanType(t("pages.companyAddress.valid.invalid_isDefault")),
        name: StringType().minLength(2, t("pages.companyAddress.valid.name_minLength")).maxLength(32, t("pages.companyAddress.valid.name_maxLength")).isRequired(t("pages.companyAddress.valid.name_required")),
        address1: StringType().minLength(2, t("pages.companyAddress.valid.address1_minLength")).maxLength(128, t("pages.companyAddress.valid.address1_maxLength")).isRequired(t("pages.companyAddress.valid.address1_required")),
        address2: StringType(),
        phone1: StringType(),
        phone2: StringType(),
        fax: StringType(),
        email1: StringType().isEmail(),
        email2: StringType().isEmail(),
        authorizedPerson: StringType(),
        countryId: NumberType().isRequired(t("pages.companyAddress.valid.countryId_required")),
        cityId: NumberType().isRequired(t("pages.companyAddress.valid.cityId_required")),
        provinceId: NumberType().isRequired(t("pages.companyAddress.valid.provinceId_required"))
    })

    const initialValues = {
        companyId: null,
        isDefault: false,
        name: "",
        address1: "",
        address2: "",
        phone1: "",
        phone2: "",
        fax: "",
        email1: "",
        email2: "",
        authorizedPerson: "",
        countryId: 1,
        cityId: null,
        provinceId: null,
    }

    const [formValues, setFormValue] = React.useState(initialValues)

    const onSubmit = async () => {
        if (!formRef.current.check()) {
            toaster.push(<Notification closable type="error" header={t('general.error.validation_error')} duration={2000}><p>{t('general.error.validation_error_description')}</p></Notification>, { placement: "topEnd" })
            return;
        }
        await createCompanyAddress(formValues).unwrap().then((response) => {
            toaster.push(<Notification closable type="success" header={t('general.error.success')} duration={2000}><p>{t('general.error.success_add')}</p></Notification>, { placement: "topEnd" })
        }
        ).catch((error) => {
            toaster.push(<Notification closable type="error" header={t('general.error.error')} duration={2000}><p>{error?.data?.description}</p></Notification>, { placement: "topEnd" })
        });
    }


    return (
        <Page title={t('pages.companyAddress.add_title')}>
            <Form fluid
                model={schema}
                formValue={formValues}
                onChange={setFormValue}
                ref={formRef}>
                <Grid fluid>
                    <Row gutter={32}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <CheckboxInput
                                name="isDefault"
                                label={t('pages.companyAddress.isDefault')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="companyId"
                                label={t('pages.companyAddress.companyId')}
                                accepter={SearchCompany}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="name"
                                label={t('pages.companyAddress.name')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="address1"
                                label={t('pages.companyAddress.address1')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="address2"
                                label={t('pages.companyAddress.address2')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="countryId"
                                label={t('pages.management.countryId')}
                                accepter={SearchCountry}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                disabled={!formValues.countryId}
                                name="cityId"
                                countryId={formValues.countryId}
                                label={t('pages.management.cityId')}
                                accepter={SearchCity}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                disabled={!formValues.cityId}
                                name="provinceId"
                                cityId={formValues.cityId}
                                label={t('pages.management.provinceId')}
                                accepter={SearchProvince}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="phone1"
                                label={t('pages.companyAddress.phone1')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="phone2"
                                label={t('pages.companyAddress.phone2')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="fax"
                                label={t('pages.companyAddress.fax')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="email1"
                                label={t('pages.companyAddress.email1')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="email2"
                                label={t('pages.companyAddress.email2')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="authorizedPerson"
                                label={t('pages.companyAddress.authorizedPerson')}
                            />
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <Divider />
                    <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button color="green" appearance="primary" onClick={onSubmit} loading={isLoading}>
                            {t("general.save")}
                        </Button>
                        <ResetButton onOk={() => setFormValue(initialValues)} disabled={isLoading} />
                    </ButtonToolbar>
                </Form.Group>
            </Form>
        </Page>
    )
}

export default AddCompany