import { Edit } from "@rsuite/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonToolbar,
  Col,
  DatePicker,
  Divider,
  FlexboxGrid,
  Form,
  Grid,
  IconButton,
  InputNumber,
  List,
  Loader,
  Modal,
  Notification,
  Row,
  Schema,
  Stack,
  Table,
  useToaster,
} from "rsuite";
import Page from "../../../components/layout/Page";
import RefDropdown from "../../../components/RefDropdown";
import TextInput from "../../../components/TextInput";
import SearchStudent from "../../../components/SearchStudent";
import {
  useGetInstallmentListByStudentEducationIdQuery,
  useConfirmInstallmentByIdMutation,
  useBulkInstallmentByIdMutation,
} from "../../../redux/studentApi";
import CheckIcon from "@rsuite/icons/Check";
import { initParams } from "../../../utils";
import moment from "moment";
import { paymentTypeEnum } from "../../../utils/enums";
const { NumberType } = Schema.Types;
const { Column, HeaderCell, Cell } = Table;

const StudentInstallment = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const [params, setParams] = React.useState(initParams);
  const [openBulkPay, setOpenBulkPay] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const initialValues = {
    studentId: null,
    studentEducationId: null,
    price: null,
  };
  const [formValues, setFormValue] = React.useState(initialValues);

  const { data, isLoading, refetch } =
    useGetInstallmentListByStudentEducationIdQuery(
      { ...params, studentEducationId: formValues.studentEducationId },
      {
        refetchOnMountOrArgChange: true,
        skip: !formValues.studentEducationId,
      }
    );

  const [update, { isLoading: submitting }] =
    useConfirmInstallmentByIdMutation();
  const [updateBulk, { isLoading: submittingBulk }] =
    useBulkInstallmentByIdMutation();

  const schema = Schema.Model({
    studentId: NumberType().isRequired(
      t("pages.student.studentGrade.valid.studentId_required")
    ),
    studentEducationId: NumberType().isRequired(
      t("pages.student.studentGrade.valid.studentEducationId_required")
    ),
  });

  const getPaidTotal = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      const e = data[i];
      if (e.isPaid) sum += Number(e.price);
    }
    return sum.toFixed(2);
  };

  const handleModalOpen = (id) => {
    setOpen(true);
    setSelectedRow(id);
  };
  const handleModalClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleOpenBulkPay = () => {
    setOpenBulkPay(true);
  };

  const handleCloseBulkPay = () => {
    setOpenBulkPay(false);
  };

  const payRow = async () => {
    if (!selectedRow) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    } else {
      await update({ id: selectedRow })
        .unwrap()
        .then((response) => {
          toaster.push(
            <Notification
              closable
              type="success"
              header={t("general.error.success")}
              duration={2000}
            >
              <p>{t("general.error.success_add")}</p>
            </Notification>,
            { placement: "topEnd" }
          );
          handleModalClose();
          refetch();
        })
        .catch((error) => {
          toaster.push(
            <Notification
              closable
              type="error"
              header={t("general.error.error")}
              duration={2000}
            >
              <p>{error?.data?.description}</p>
            </Notification>,
            { placement: "topEnd" }
          );
        });
    }
    setSelectedRow(null);
  };

  const bulkPayment = async () => {
    if (!formValues.price || formValues.price < 1) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    } else {
      await updateBulk({
        studentEducationId: formValues.studentEducationId,
        price: formValues.price,
      })
        .unwrap()
        .then((response) => {
          toaster.push(
            <Notification
              closable
              type="success"
              header={t("general.error.success")}
              duration={2000}
            >
              <p>{t("general.error.success_add")}</p>
            </Notification>,
            { placement: "topEnd" }
          );
          handleCloseBulkPay();
          refetch();
        })
        .catch((error) => {
          toaster.push(
            <Notification
              closable
              type="error"
              header={t("general.error.error")}
              duration={2000}
            >
              <p>{error?.data?.description}</p>
            </Notification>,
            { placement: "topEnd" }
          );
        });
    }
  };

  return (
    <Page title={t("pages.student.studentInstallment.title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="studentId"
                label={t("pages.student.studentGrade.studentId")}
                accepter={SearchStudent}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="studentEducationId"
                endpoint="student/education"
                label={t("pages.student.studentGrade.studentEducationId")}
                accepter={RefDropdown}
                skip={!formValues.studentId}
                query={{ studentId: formValues.studentId }}
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row gutter={16}>
            {isLoading ? (
              <Loader
                size="lg"
                backdrop
                content={`${t("general.loading")}...`}
                vertical
              />
            ) : (
              formValues.studentEducationId &&
              data?.data?.map((values, vIndex) => (
                <List bordered key={vIndex}>
                  <List.Item style={{ margin: 10 }}>
                    <Stack
                      divider={<Divider vertical />}
                      justifyContent="space-between"
                    >
                      <Button>
                        {t("pages.student.studentEducation.paymentTypeId")} :{" "}
                        {values.paymentTypeId === paymentTypeEnum.installment
                          ? "Taksit"
                          : "Peşin"}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.totalPrice")} :{" "}
                        {values.price}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.discount")} :{" "}
                        {(values?.studentEducation_studentPaymentDiscount && values?.studentEducation_studentPaymentDiscount.length > 0 ?
                          values?.studentEducation_studentPaymentDiscount[0].discountTypeId === 1 ? Number(values?.studentEducation_studentPaymentDiscount[0].discountRate).toFixed(2) : (Number(values.price) * Number(values?.studentEducation_studentPaymentDiscount[0].discountRate) / 100).toFixed(2) : 0)}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.paidPrice")} :{" "}
                        {getPaidTotal(
                          values.studentPayment_studentPaymentDetail || []
                        )}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.remainPrice")} :{" "}
                        {(
                          Number(values.price) - (values?.studentEducation_studentPaymentDiscount && values?.studentEducation_studentPaymentDiscount.length > 0 ?
                            values?.studentEducation_studentPaymentDiscount[0].discountTypeId === 1 ? Number(values?.studentEducation_studentPaymentDiscount[0].discountRate) : (Number(values.price) * Number(values?.studentEducation_studentPaymentDiscount[0].discountRate) / 100) : 0) -
                          getPaidTotal(
                            values.studentPayment_studentPaymentDetail || []
                          )
                        ).toFixed(2)}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.centerRate")} :{" "}
                        {Number(values.agencyRate) !== 100
                          ? 100 - Number(values.agencyRate) + "%"
                          : "100%"}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.agencyRate")} :{" "}
                        {Number(values.agencyRate) !== 100
                          ? values.agencyRate + "%"
                          : "0%"}
                      </Button>
                    </Stack>
                  </List.Item>
                  <List.Item style={{ margin: 10 }}>
                    <Table
                      autoHeight
                      data={[
                        ...values.studentPayment_studentPaymentDetail,
                      ].sort(
                        (a, b) =>
                          Number(a.installmentNo) - Number(b.installmentNo)
                      )}
                      // onRowClick={rowData => {
                      //     console.log(rowData);
                      // }}
                      bordered
                      cellBordered
                    >
                      <Column width={100} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.pay")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) =>
                            !rowData.isPaid ? (
                              <IconButton
                                onClick={() => handleModalOpen(rowData.id)}
                                icon={<CheckIcon />}
                                circle
                                size="xs"
                              />
                            ) : (
                              ""
                            )
                          }
                        </Cell>
                      </Column>
                      <Column width={100} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.installmentNo")}
                        </HeaderCell>
                        <Cell dataKey="installmentNo" />
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.paymentTypeId")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) =>
                            rowData.installmentNo === 0 ? "Peşinat" : "Taksit"
                          }
                        </Cell>
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.price")}
                        </HeaderCell>
                        <Cell dataKey="price" />
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.isPaid")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) => (rowData.isPaid ? "Evet" : "Hayır")}
                        </Cell>
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.expiry")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) =>
                            rowData.dueDate
                              ? moment(rowData.dueDate).format("DD/MM/YYYY")
                              : "-"
                          }
                        </Cell>
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.paidDate")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) =>
                            rowData.paymentDate
                              ? moment(rowData.paymentDate).format(
                                "DD/MM/YYYY HH:mm"
                              )
                              : "-"
                          }
                        </Cell>
                      </Column>
                    </Table>
                  </List.Item>
                </List>
              ))
            )}
          </Row>
        </Grid>
        <Grid fluid>
          <Form.Group>
            <Divider />
            <ButtonToolbar
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Button
                disabled={!formValues.studentEducationId}
                onClick={() => handleOpenBulkPay()}
              >
                {t("pages.student.studentEducation.bulkPay")}
              </Button>
            </ButtonToolbar>
          </Form.Group>
        </Grid>
        <Modal
          size="xs"
          role="alertdialog"
          backdrop="static"
          keyboard={false}
          open={open}
          onClose={handleModalClose}
        >
          <Modal.Header>
            <Modal.Body>
              {t("pages.student.studentEducation.pay_confirm")}
              <Divider />
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextInput
                  name="paymentDate"
                  style={{ width: "100%" }}
                  label={t("pages.student.studentEducation.paidDate")}
                  accepter={DatePicker}
                  format="dd.MM.yyyy"
                />
              </Col>
            </Modal.Body>
          </Modal.Header>
          <Modal.Footer>
            <Button
              onClick={() => payRow()}
              appearance="primary"
              loading={submitting}
            >
              {t("general.yes")}
            </Button>
            <Button
              onClick={handleModalClose}
              appearance="subtle"
              loading={submitting}
            >
              {t("general.cancel")}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          backdrop="static"
          keyboard={false}
          open={openBulkPay}
          onClose={handleCloseBulkPay}
        >
          <Modal.Header>
            <Modal.Title>
              {t("pages.student.studentEducation.bulkPayDescription")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="price"
                    label={t("pages.student.studentEducation.price")}
                    min={0}
                    accepter={InputNumber}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="paymentDate"
                    style={{ width: "100%" }}
                    label={t("pages.student.studentEducation.paidDate")}
                    accepter={DatePicker}
                    format="dd.MM.yyyy"
                  />
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => bulkPayment()}
              loading={submitting}
              appearance="primary"
            >
              {t("general.save")}
            </Button>
            <Button
              onClick={handleCloseBulkPay}
              loading={submitting}
              appearance="subtle"
            >
              {t("general.cancel")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </Page>
  );
};

export default StudentInstallment;
