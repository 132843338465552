import { useMemo } from "react";
import { useGetCompanyListQuery } from "../redux/companyApi";
import { initParams } from "../utils";
const useCompany = (key) => {
    const { data, isLoading } = useGetCompanyListQuery({ ...initParams, name: key }, { skip: !key })
    const companies = data?.data || []
    return [companies, isLoading];
    // return useMemo(() => ({ companies, isLoading }), [companies, isLoading])
};

export default useCompany