import { Edit, FileDownload } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Grid, IconButton, Row, Schema } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import { useGetGraduateStudentListQuery } from "../../../redux/studentApi";
import { initParams } from "../../../utils";
import { useAppDispatch } from "../../../hooks"
import { printOrder } from "../../../utils/exportTemplates/order/printOrder";
import { api } from "../../../redux/api";
import TextInput from "../../../components/TextInput";
import SearchStudent from "../../../components/SearchStudent";

const GraduateStudents = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formRef = React.useRef();
    const initialValues = {
        studentId: null,
    };

    const [formValues, setFormValue] = React.useState(initialValues);
    const [params, setParams] = useState(initParams);
    const { data, isLoading, refetch } = useGetGraduateStudentListQuery({ ...params, studentId: formValues.studentId }, {
        refetchOnMountOrArgChange: true,
    });

    const edit = (data) => { };


    const print = async (id) => {
        const { data } = await dispatch(api.endpoints.getTranskript.initiate({ studentEducationId: id }, { forceRefetch: true }))
        if (data.status === "success") {
            printOrder(data?.data)
        }
        else {
            //hata
        }

    }

    const columns = [
        {
            title: t("pages.student.tcKimlikNo"),
            name: "studentEducation_student.tcKimlikNo",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.name"),
            name: "studentEducation_student.name",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.surname"),
            name: "studentEducation_student.surname",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.studentEducation.softwareId"),
            name: "studentEducation_software.name",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.education"),
            name: "studentEducation_education.name",
            sortable: true,
            align: "left",
            type: "text",
            fullText: true,
            flexGrow: 1,
        },
        {
            title: t("pages.student.email"),
            name: "studentEducation_student.email",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.phone"),
            name: "studentEducation_student.phone",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.studentEducation.certificateNo"),
            name: "certificateNo",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.studentEducation.certificateDate"),
            name: "certificateDate",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("general.table.actions"),
            name: "actions",
            width: 100,
            renderCell: (rowData) => (
                <>
                    <Button color="cyan" size="xs" onClick={() => print(rowData.id)} appearance="primary" startIcon={<FileDownload />}>
                        Transkript
                    </Button>
                    {/* <IconButton
                        onClick={() => print(rowData.id)}
                        size="xs"
                        title="Transkript"
                        icon={<FileDownload color="orange" />}
                    /> */}
                </>
            ),
        },
    ];

    return (
        <Page title={t("pages.student.graduateStudentList")}>
            <Form
                fluid
                formValue={formValues}
                onChange={setFormValue}
                ref={formRef}
            >
                <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <TextInput
                                name="studentId"
                                label={t("pages.student.studentGrade.studentId")}
                                accepter={SearchStudent}
                            />
                        </Col>
                    </Row>
                </Grid>
            </Form>
            <DataTable
                size="compact"
                wordWrap="break-word"
                columns={columns}
                rowKey="id"
                loading={isLoading}
                data={data?.data}
                params={params}
                rowCount={data?.total}
                setPagination={setParams}
                refetch={refetch}
            />
        </Page>
    );
};

export default GraduateStudents;
