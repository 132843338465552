import React from "react";
import { Form, Uploader } from "rsuite";

const Upload = React.forwardRef((props, ref) => <Uploader {...props} ref={props.innerRef} />);


const FileUpload = (props) => {
    const { name, message, action = "#", listType = "picture", multiple = false, accept = null, autoUpload = false, label, error, disabled = false, readOnly = false, style, innerRef = null, ...rest } = props

    return (
        <Form.Group className={error ? 'has-error' : ''} style={{ marginBottom: 24 }}>
            <Form.ControlLabel>{label && `${label} :`}</Form.ControlLabel>
            <Form.Control style={style} disabled={disabled} readOnly={readOnly} name={name} accept={accept} autoUpload={autoUpload} multiple={multiple} accepter={Upload} errorMessage={error} action={action} listType={listType} innerRef={innerRef}  {...rest} />
            {message && <Form.HelpText>{message}</Form.HelpText>}
        </Form.Group>
    )
}

export default FileUpload