import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Col, Divider, Form, Grid, Notification, Row, Schema, useToaster } from "rsuite";
import Page from "../../../components/layout/Page";
import PasswordInput from "../../../components/PasswordInput";
import { useChangePasswordMutation } from "../../../redux/settingApi";

const { StringType } = Schema.Types

const ChangePassword = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const toaster = useToaster();
    const [changePassword, { isLoading }] = useChangePasswordMutation()

    const schema = Schema.Model({
        password: StringType().isRequired(t('pages.user.login.valid.passwordRequired')).minLength(8, t("pages.user.login.valid.password_minLength")).maxLength(20, t("pages.user.login.valid.password_maxLength")),
        passwordAgain: StringType().isRequired(t('pages.user.login.valid.passwordRequired')).addRule((value, data) => {
            if (value !== data.password) {
                return false;
            }
            return true;
        }, t('pages.user.login.valid.password_twice'))
    })

    const initialValues = {
        password: "",
        passwordAgain: "",
    }

    const [formValues, setFormValue] = React.useState(initialValues)

    const onSubmit = async () => {
        if (!formRef.current.check()) {
            toaster.push(<Notification closable type="error" header={t('general.error.validation_error')} duration={2000}><p>{t('general.error.validation_error_description')}</p></Notification>, { placement: "topEnd" })
            return;
        }
        await changePassword(formValues).unwrap().then((response) => {
            toaster.push(<Notification closable type="success" header={t('general.error.success')} duration={2000}><p>{t('general.error.success_add')}</p></Notification>, { placement: "topEnd" })
        }
        ).catch((error) => {
            toaster.push(<Notification closable type="error" header={t('general.error.error')} duration={2000}><p>{error?.data?.description}</p></Notification>, { placement: "topEnd" })
        });
    }


    return (
        <Page title={t('pages.user.login.change_password')}>
            <Form fluid
                model={schema}
                formValue={formValues}
                onChange={setFormValue}
                ref={formRef}>
                <Grid fluid>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <PasswordInput
                                name="password"
                                label={t('pages.user.login.password')}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <PasswordInput
                                name="passwordAgain"
                                label={t('pages.user.login.passwordAgain')}
                            />
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <Divider />
                    <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button color="green" appearance="primary" onClick={onSubmit} loading={isLoading}>
                            {t("general.save")}
                        </Button>
                    </ButtonToolbar>
                </Form.Group>
            </Form>

        </Page>
    )
}

export default ChangePassword