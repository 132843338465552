import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  ButtonToolbar,
  Col,
  DatePicker,
  Divider,
  Form,
  Grid,
  Loader,
  Modal,
  Notification,
  Panel,
  Row,
  Schema,
  Uploader,
  useToaster,
} from "rsuite";
import Page from "../../../components/layout/Page";
import TextInput from "../../../components/TextInput";
import {
  useGetStudentByIdQuery,
  useUpdateLoginStatusByStudentIdMutation,
  useUpdateStudentMutation,
} from "../../../redux/studentApi";
import AdminIcon from "@rsuite/icons/Admin";
import SearchCountry from "../../../components/SearchCountry";
import TextArea from "../../../components/TextArea";
import EnumDropdown from "../../../components/EnumDropdown";
import { GenderOptions } from "../../../utils/options";
import moment from "moment";
import StudentNavbar from "../components/StudentNavbar";
import EducationList from "./components/EducationList";
const { StringType, NumberType, ArrayType, BooleanType, DateType, ObjectType } =
  Schema.Types;

const UpdateStudent = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const formRef = React.useRef();
  const toaster = useToaster();
  const { id } = useParams();
  const [active, setActive] = React.useState("educations");
  const [selectedTask, setSelectedTask] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [updateStudent, { isLoading }] = useUpdateStudentMutation();
  const [updateStudentLogin, { isLoading: loginUpdateLoading }] =
    useUpdateLoginStatusByStudentIdMutation();
  const {
    data: student,
    isLoading: fetchLoading,
    refetch,
  } = useGetStudentByIdQuery(
    { id: id },
    { skip: !id, refetchOnMountOrArgChange: true }
  );
  const [formGeneralValues, setFormGeneralValue] = React.useState(
    student?.data
  );

  React.useEffect(() => {
    if (student?.data) {
      setFormGeneralValue({
        ...student?.data,
        birthDate: new Date(student?.data.birthDate),
      });
    }
  }, [student?.data]);

  const schemaGeneral = Schema.Model({
    countryId: NumberType().isRequired(
      t("pages.student.valid.countryId_required")
    ),
    tcKimlikNo: StringType()
      .isRequired(t("pages.student.valid.tcKimlikNo_required"))
      // .minLength(11, t("pages.student.valid.tcKimlikNo_minLength"))
      .maxLength(20, t("pages.student.valid.tcKimlikNo_maxLength")),
    name: StringType()
      .isRequired(t("pages.student.valid.name_required"))
      .minLength(1, t("pages.student.valid.name_minLength"))
      .maxLength(64, t("pages.student.valid.name_maxLength")),
    surname: StringType()
      .isRequired(t("pages.student.valid.surname_required"))
      .minLength(1, t("pages.student.valid.surname_minLength"))
      .maxLength(64, t("pages.student.valid.surname_maxLength")),
    placeOfBirth: StringType().maxLength(
      64,
      t("pages.student.valid.placeOfBirth_maxLength")
    ),
    genderId: NumberType().isRequired(
      t("pages.student.valid.genderId_required")
    ),
    phone: StringType()
      .isRequired(t("pages.student.valid.phone_required"))
      .maxLength(16, t("pages.student.valid.phone_maxLength")),
    email: StringType()
      .isRequired(t("pages.student.valid.email_required"))
      .isEmail(t("pages.student.valid.email_invalid"))
      .maxLength(64, t("pages.student.valid.email_maxLength")),
  });

  const onGeneralSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.validation_error")}
          duration={2000}
        >
          <p>{t("general.error.validation_error_description")}</p>
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }
    await updateStudent({
      ...formGeneralValues,
      file: selectedTask ? selectedTask.file : null,
      ext: selectedTask ? selectedTask.ext : null,
    })
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        history(`/student/${formGeneralValues.id}`);
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const closeLogin = async () => {
    await updateStudentLogin({ userId: student?.data?.userId, status: false })
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        refetch();
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const openLogin = async () => {
    await updateStudentLogin({ userId: student?.data?.userId, status: true })
      .unwrap()
      .then((response) => {
        toaster.push(
          <Notification
            closable
            type="success"
            header={t("general.error.success")}
            duration={2000}
          >
            <p>{t("general.error.success_add")}</p>
          </Notification>,
          { placement: "topEnd" }
        );
        refetch();
      })
      .catch((error) => {
        toaster.push(
          <Notification
            closable
            type="error"
            header={t("general.error.error")}
            duration={2000}
          >
            <p>{error?.data?.description}</p>
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedTask(null);
    setOpen(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onerror = reject;
      fileReader.onload = function () {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const handleFileInputChange = (e) => {
    if (e.length > 0) {
      let file = e[0];
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(e[0].name)[1];
      getBase64(file.blobFile)
        .then((result) => {
          setSelectedTask({ ...selectedTask, file: result, ext: ext });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (fetchLoading) {
    return (
      <Loader
        vertical
        backdrop
        center
        size="lg"
        content={`${t("general.loading")}...`}
      />
    );
  }
  return (
    <Page title={student?.data?.name + " " + student?.data?.surname}>
      <Grid fluid>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form
              fluid
              model={schemaGeneral}
              formValue={formGeneralValues}
              onChange={setFormGeneralValue}
              ref={formRef}
              disabled={fetchLoading || loginUpdateLoading}
            >
              <Panel
                header="Genel Bilgiler"
                bordered
                shaded
                bodyFill
                style={{ padding: 20 }}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ textAlign: "center", marginBottom: 20 }}
                >
                  {student?.data?.photoPath ? (
                    <img
                      style={{ height: 150, width: 150 }}
                      src={`https://admin.bcuni.eu/api/public/documents/${student?.data?.photoPath}`}
                      alt={student?.data?.photoPath}
                    />
                  ) : (
                    <Avatar size="lg" style={{ height: 150, width: 150 }}>
                      <AdminIcon style={{ height: 200 }} />
                    </Avatar>
                  )}
                  <ButtonToolbar
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <Button
                      color="green"
                      appearance="primary"
                      onClick={handleOpen}
                      loading={isLoading || loginUpdateLoading}
                    >
                      Fotoğraf Değiştir
                    </Button>
                  </ButtonToolbar>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="tcKimlikNo"
                    label={t("pages.student.tcKimlikNo")}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput name="name" label={t("pages.student.name")} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="surname"
                    label={t("pages.student.surname")}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="birthDate"
                    label={t("pages.student.birthDate")}
                    accepter={DatePicker}
                    style={{ width: "100%" }}
                    format="dd.MM.yyyy"
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="countryId"
                    label={t("pages.student.countryId")}
                    accepter={SearchCountry}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="genderId"
                    label={t("pages.student.genderId")}
                    accepter={EnumDropdown}
                    data={GenderOptions}
                    prefix="general.enums.gender"
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="fatherName"
                    label={t("pages.student.fatherName")}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="motherName"
                    label={t("pages.student.motherName")}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="placeOfBirth"
                    label={t("pages.student.placeOfBirth")}
                  />
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput name="phone" label={t("pages.student.phone")} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput name="email" label={t("pages.student.email")} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TextInput
                    name="address"
                    label={t("pages.student.address")}
                    rows={3}
                    accepter={TextArea}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Group>
                    <Divider />
                    <ButtonToolbar
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 20,
                      }}
                    >
                      <Button
                        color="green"
                        appearance="primary"
                        onClick={onGeneralSubmit}
                        loading={isLoading || loginUpdateLoading}
                      >
                        {t("general.update")}
                      </Button>
                      <Button
                        color="green"
                        appearance="primary"
                        onClick={
                          formGeneralValues?.student_users?.status
                            ? closeLogin
                            : openLogin
                        }
                        loading={isLoading || loginUpdateLoading}
                      >
                        {formGeneralValues?.student_users?.status
                          ? t("general.closeLogin")
                          : t("general.openLogin")}
                      </Button>
                    </ButtonToolbar>
                  </Form.Group>
                </Col>
              </Panel>
            </Form>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Panel
              header="Eğitim Bilgileri"
              bordered
              shaded
              bodyFill
              style={{ padding: 20 }}
            >
              <StudentNavbar
                appearance="subtle"
                active={active}
                onSelect={setActive}
              />
              {active === "educations" && <EducationList studentId={id} />}
            </Panel>
          </Col>
        </Row>
        <Modal size="sm" backdrop="static" open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>Fotoğraf Değiştir</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Uploader
              onChange={handleFileInputChange}
              draggable
              disabled={selectedTask && selectedTask.file}
              autoUpload={false}
              onRemove={() => setSelectedTask({ ...selectedTask, file: null })}
              accept="image/jpg,image/png,image/svg+xml,image/jpeg"
            >
              <div
                style={{
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Dosya Yüklemek için sürükleyin veya tıklayın</span>
              </div>
            </Uploader>
          </Modal.Body>
          <Modal.Footer>
            <Button
              loading={isLoading}
              onClick={onGeneralSubmit}
              appearance="primary"
            >
              {t("general.save")}
            </Button>
            <Button
              disabled={isLoading}
              onClick={handleClose}
              appearance="subtle"
            >
              {t("general.cancel")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Grid>
    </Page>
  );
};

export default UpdateStudent;
