import { Edit } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconButton } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import { useGetNextToGraduateStudentListQuery } from "../../../redux/studentApi";
import { initParams } from "../../../utils";

const NextToGraduateStudents = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const [params, setParams] = useState(initParams);
    const { data, isLoading, refetch } = useGetNextToGraduateStudentListQuery(params, {
        refetchOnMountOrArgChange: true,
    });

    const edit = (data) => {
        history(`/student/${data.studentId}`);
    };

    const columns = [
        {
            title: t("pages.student.tcKimlikNo"),
            name: "studentEducation_student.tcKimlikNo",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.name"),
            name: "studentEducation_student.name",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.surname"),
            name: "studentEducation_student.surname",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.studentEducation.softwareId"),
            name: "studentEducation_software.name",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.education"),
            name: "studentEducation_education.name",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.email"),
            name: "studentEducation_student.email",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("pages.student.phone"),
            name: "studentEducation_student.phone",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1,
        },
        {
            title: t("general.table.actions"),
            name: "actions",
            width: 100,
            renderCell: (rowData) => (
                <>
                    <IconButton
                        onClick={() => edit(rowData)}
                        size="xs"
                        icon={<Edit color="orange" />}
                    />
                </>
            ),
        },
    ];

    return (
        <Page title={t("pages.student.nextToGraduateStudentList")}>
            <DataTable
                size="compact"
                columns={columns}
                rowKey="id"
                loading={isLoading}
                data={data?.data}
                params={params}
                rowCount={data?.total}
                setPagination={setParams}
                refetch={refetch}
            />
        </Page>
    );
};

export default NextToGraduateStudents;
