import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import EditIcon from '@rsuite/icons/Edit';
import { useGetCompanyListQuery } from "../../../redux/companyApi";
import { initParams } from "../../../utils";

const Companies = () => {
    const { t } = useTranslation();

    const [params, setParams] = useState(initParams)
    const { data, isLoading, refetch } = useGetCompanyListQuery(params, { refetchOnMountOrArgChange: true })

    const edit = (data) => {

    }

    const columns = [
        {
            title: t("pages.company.name"),
            name: "name",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 2
        },
        {
            title: t("pages.company.shortName"),
            name: "shortName",
            sortable: true,
            align: "left",
            type: "text",
            width: 100
        },
        {
            title: t("pages.company.taxOffice"),
            name: "taxOffice",
            sortable: true,
            align: "left",
            type: "text",
            width: 120
        },
        {
            title: t("pages.company.taxNo"),
            name: "taxNo",
            sortable: true,
            align: "right",
            type: "text",
            width: 120
        },
        {
            title: t("pages.company.mersis"),
            name: "mersis",
            sortable: true,
            align: "left",
            type: "text",
            flexGrow: 1
        },
        {
            title: t("general.table.actions"),
            name: "actions",
            width: 100,
            renderCell: rowData => (<IconButton onClick={() => edit(rowData)} size="xs" icon={<EditIcon color="orange" />} />)
        }
    ]

    return (
        <Page title={t("pages.company.title")}>
            <DataTable
                create="/company/add"
                size="compact"
                columns={columns}
                rowKey="id"
                loading={isLoading}
                data={data?.data}
                params={params}
                rowCount={data?.total}
                setPagination={setParams}
                refetch={refetch}
            />
        </Page>
    )
}

export default Companies