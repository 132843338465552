import { Minus } from "@rsuite/icons";
import { useTranslation } from "react-i18next";
import { ButtonGroup, Col, IconButton, Row } from "rsuite";
import TextInput from "../../../components/TextInput";
import TextArea from "../../../components/TextArea";
import EnumDropdown from "../../../components/EnumDropdown";
import { LessonResourceTypesOptions } from "../../../utils/options";

const LessonComponent = ({
  rowValue = {},
  onChange,
  rowIndex,
  rowError,
  handleMinus,
}) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange(rowIndex, { ...rowValue, [name]: value });
  };

  return (
    <Row gutter={1}>
      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
        <TextInput
          name="resources.name"
          value={rowValue.name}
          onChange={(e) => handleChange(e, "name")}
          error={rowError?.name?.errorMessage}
          label={t("pages.lessonsResources.name")} />
          
      </Col>
      <Col xs={24} sm={3} md={3} lg={3} xl={3}>
        <TextInput
          name="resources.lessonResourceTypeId"
          label={t("pages.lessonsResources.lessonResource_type")}
          accepter={EnumDropdown}
          data={LessonResourceTypesOptions}
          value={rowValue.lessonResourceTypeId}
          onChange={(e) => handleChange(e, "lessonResourceTypeId")}
          error={rowError?.lessonResourceTypeId?.errorMessage}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
        <TextInput
          name="resources.resource"
          label={t("pages.lessonsResources.resource")}
          value={rowValue.resource}
          onChange={(e) => handleChange(e, "resource")}
          error={rowError?.resource?.errorMessage}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
        <TextInput
          name="resources.description"
          label={t("pages.lessonsResources.description")}
          value={rowValue.description}
          onChange={(e) => handleChange(e, "description")}
          error={rowError?.description?.errorMessage}
          rows={3}
        />
      </Col>
      <Col xs={24} sm={12} md={2} lg={1} xl={1}>
        <ButtonGroup size="xs" style={{ marginTop: 12 }}>
          <IconButton onClick={() => handleMinus(rowIndex)} icon={<Minus />} />
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default LessonComponent;
